/* eslint-disable no-console */
import { asset } from "@assets/index";
import {
  FINMART_ID_BRAND_ID,
  FINMART_VN_BRAND_ID,
  FINMERCADO_MX_BRAND_ID,
  FINMERKADO_PH_BRAND_ID,
  JEFF_VN_BRAND_ID,
  LOANONLINE_BRAND_ID,
  PAISA247_IN_BRAND_ID,
} from "../constants";
import type { BrandId } from "./types";
const jeffLogo = asset("icons/favicons/jeffLogo.png");
const finmartLogo = asset(
  "icons/favicons/finmart-color-multi-icononly-medium.png",
);
const loanonlineLogo = asset("icons/favicons/loanonline.svg");

const brandIdToFaviconMap: Record<BrandId, string> = {
  [JEFF_VN_BRAND_ID]: jeffLogo,
  [FINMART_ID_BRAND_ID]: finmartLogo,
  [FINMART_VN_BRAND_ID]: finmartLogo,
  [LOANONLINE_BRAND_ID]: loanonlineLogo,
  [FINMERCADO_MX_BRAND_ID]: finmartLogo,
  [FINMERKADO_PH_BRAND_ID]: finmartLogo,
  [PAISA247_IN_BRAND_ID]: asset("icons/favicons/paisa247-favicon.png"),
};

export const getFaviconFromBrandId = (
  brandId: BrandId,
  onError: (err: Error | string) => void | Promise<void> = console.error,
) => {
  if (!brandId) {
    void onError("getFaviconFromBrandId: no brandId provided");
  }
  const brandIdOverride = process.env.NEXT_PUBLIC_LOCAL_DEV_BRAND_ID as BrandId;
  return brandIdToFaviconMap[brandIdOverride || brandId] || jeffLogo;
};
