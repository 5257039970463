import { getProfileConfig } from "@domain/profile";
import type { ProductConfig } from "@domain/product-config/types";

export const jeffVNConfig: ProductConfig = {
  countryCode: "VN",
  socialProviderConfig: {
    enabledSocialProviders: ["google", "zalo"],
  },
  profileConfig: {
    ...getProfileConfig({ brandId: "jeff-vn" }),
  },
  flowConfig: {
    isUnifiedFlowEnabled: true,
  },
  adSense: {
    enabled: true,
  },
  userFeedback: {
    canShowFeedbackInOfferCards: true,
  },
  otp: {
    enabled: true,
  },
  captcha: {
    enabled: true,
  },
  levelUp: {
    enabled: false,
  },
  feedback: {
    enabled: true,
  },
  activityListingInOffers: { enabled: true },
  olFeedbackPopUp: { enabled: true },
};

export const finmartVNConfig: ProductConfig = {
  countryCode: "VN",
  socialProviderConfig: {
    enabledSocialProviders: [],
  },
  profileConfig: {
    ...getProfileConfig({ brandId: "finmart-vn" }),
    enabled: false,
  },
  flowConfig: {
    isUnifiedFlowEnabled: true,
  },
  adSense: {
    enabled: false,
  },
  userFeedback: {
    canShowFeedbackInOfferCards: true,
  },
  otp: {
    enabled: false,
  },
  captcha: {
    enabled: false,
  },
  levelUp: { enabled: true },
  feedback: {
    enabled: true,
  },
  olFeedbackPopUp: {
    enabled: true,
  },
  offerListing: {
    crossBrandOffers: { enabled: true },
  },
};
