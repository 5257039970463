import {
  LOCALHOST_COOKIE_DOMAIN,
  LOCALHOST_DOMAIN,
} from "../constants/constants";
import { finmartIdDevBranding } from "./finmart-id-dev";
import { jeffVNDevBranding } from "./jeff-vn-dev";
import { finmartVnDevBranding } from "./finmart-vn-dev";
import { finmercadoMxDevBranding } from "./finmercado-mx-dev";
import { loanonlineDevBranding } from "./loanonline-dev";
import { finmerkadoPhBranding } from "./finmerkado-ph";
import { paisa247Branding } from "./paisa247-in";
import type { BrandId, Branding } from "../common/types";

const brandIdOverride = process.env.NEXT_PUBLIC_LOCAL_DEV_BRAND_ID as BrandId;

const brandings = [
  finmartIdDevBranding,
  jeffVNDevBranding,
  finmartVnDevBranding,
  finmercadoMxDevBranding,
  loanonlineDevBranding,
  finmercadoMxDevBranding,
  finmerkadoPhBranding,
  paisa247Branding,
];

export const localhostBranding: Branding = {
  ...(brandings.find((branding) => {
    return branding.brandId === brandIdOverride;
  }) || jeffVNDevBranding),
  cookieDomain: LOCALHOST_COOKIE_DOMAIN,
  brandDomain: LOCALHOST_DOMAIN,
  // homepage: LOCALHOST_HOMEPAGE,
  // brandId: brandIdOverride || jeffVNDevBranding.brandId,
};
