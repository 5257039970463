import { hi } from "date-fns/locale";
import { allPhonePrefixes } from "../types";
import type { LocalisationConfig } from "../types";

export const inItems: LocalisationConfig = {
  country: "IN",
  allowedLanguages: ["hi"],
  defaultLanguage: "hi",
  hrefLang: "hi-IN",
  currency: {
    abbreviation: "INR",
    symbol: "₹",
    format: (amount: number) =>
      amount.toLocaleString("hi-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
  },
  phonePrefix: allPhonePrefixes.IN,
  locale: hi,
  dateInputFormat: "dd/MM/yyyy",
  countryName: "भारत",
};
