import { trackAdSlotStatusChange } from "@jeff/web-analytics/snowplow";
import { type ReactNode, useRef, useState } from "react";
import {
  PARAM_NAME_FORCE_ADSENSE,
  PARAM_NAME_FORCE_GPT,
} from "@jeff/web-analytics/src/marketing";
import { type QualityScore, type ProductType } from "@jeff/lead-api";
import { useRouter } from "next/router";
import { onError } from "@integration/bugsnag/csr";
import { getAdSenseClientId, getAdSlotName } from "@integration/adsense/config";
import { AdSenseScript } from "@components/scripts/AdSenseScript";
import { branding } from "@domain/branding/common";
import { useExperiments } from "@providers/ExperimentsProvider/hooks/useExperiments";
import { fetchProductConfigCSR } from "@domain/product-config/csr";
import { useFilteredQueryParams } from "@integration/query-params/csr";
import {
  type AdPlacementConfig,
  type AdPlacement,
} from "@integration/adsense/types";
import { trackAdsOfferListing } from "@integration/adsense/event-tracking";
import { findAdsStatus } from "@integration/adsense/ads-status-supplier";
import { type LeadServicesInfo } from "@domain/leads/ssr/fetchLeadById";
import { GooglePublisherTagsScript } from "@components/scripts/GooglePublisherTagsScript";
import { getDeviceData } from "@integration/device/csr";
import { isFeatureEnabled } from "@domain/features/csr";
import { OL_ANCHOR_AD_EXP_ID } from "@integration/experiments/constants";
import { AdsGlobalContext, type AdsMetadata } from "./context/AdsGlobalContext";
import { isIncorrectAdsenseUsageDetected } from "./hooks/isIncorrectAdsenseUsageDetected";
import { getUtmSource } from "./utils/getUtmSource";
import { initGPT } from "./utils/initGPT";
import { getAgeGroup } from "./utils/ageGroup";

type Props = {
  children: ReactNode;
};

export const AdsGlobalProvider = ({ children }: Props) => {
  const { pathname, query } = useRouter();
  const [isAdSenseReady, setIsAdSenseReady] = useState(false);
  const [isGPTReady, setIsGPTReady] = useState(false);
  const [renderGPT, setRenderGPT] = useState(false);
  const [adPlacements, setAdPlacements] = useState<AdPlacementConfig>(
    new Map<AdPlacement, string>(),
  );
  const [adsMetadata, setAdsMetadata] = useState<AdsMetadata>({});

  const params = useFilteredQueryParams();
  const { experiments } = useExperiments();

  const hasFinishedSetup = useRef(false);

  const { brandId } = branding;
  const deviceData = getDeviceData();

  const handleInitAds = ({
    offerListing,
    lead,
    qualityScore,
  }: {
    offerListing?: {
      id: string;
      count: number;
      products: { productType: ProductType; count: number }[];
      offers: { id: string }[];
    };
    lead?: LeadServicesInfo | null;
    qualityScore?: QualityScore;
  }) => {
    const isInvalidAdsenseUsage = isIncorrectAdsenseUsageDetected({
      pathname,
      query,
    });

    if (isInvalidAdsenseUsage) {
      return;
    }

    if (offerListing && experiments && !hasFinishedSetup.current) {
      const gender = lead?.gender?.toUpperCase();
      const age = lead?.age;
      const adSenseProductConfig = fetchProductConfigCSR(brandId).adSense;
      const { utmSource, utmCampaign, utmMedium } = getUtmSource(params);
      const showAnchorAd = isFeatureEnabled({
        feature: OL_ANCHOR_AD_EXP_ID,
        experiments,
      });

      const adsStatus = findAdsStatus({
        onError,
        siteId: brandId,
        config: adSenseProductConfig,
        age,
        experiments,
        forced: params?.[PARAM_NAME_FORCE_ADSENSE] === "true",
        forcedGpt: params?.[PARAM_NAME_FORCE_GPT] === "true",
        offerListing,
        gender,
        device: { brand: deviceData.vendor },
        utm: {
          source: utmSource,
          campaign: utmCampaign,
          medium: utmMedium,
        },
        address: {
          city: lead?.addressCity || undefined,
        },
        isDaysOnBookZero: lead?.isDaysOnBookZero,
        hasMoneyEvent: lead?.hasMoneyEvent,
        hasNoRedirects: (lead?.redirectCount || 0) === 0,
        isFresh: lead?.fresh,
        qualityScore: qualityScore?.score || null,
        showAnchorAd,
      });

      if (adsStatus) {
        const shouldRenderGPT = adsStatus.show && adsStatus.adsProduct == "gpt";

        setRenderGPT(shouldRenderGPT);

        const adProvider = adsStatus.adsProduct || "none";
        trackAdsOfferListing({
          offerListingId: offerListing.id,
          adProvider,
          customerSegment: adsStatus.segmentName,
          adShown: adsStatus.show,
          adApproach: adsStatus.approach,
          deviceBrand: deviceData.vendor,
          experimentId: adsStatus.experimentId,
          experimentVariant: adsStatus.experimentVariant,
        });

        if (adsStatus.placements) {
          setAdPlacements(adsStatus.placements);
        }

        setAdsMetadata(
          getAdsMetadata({
            lead,
            offerCount: offerListing.count,
            experimentId: adsStatus.experimentId,
            qualityScore,
          }),
        );
      }

      hasFinishedSetup.current = true;
    }
  };

  const getAdsMetadata = ({
    lead,
    offerCount,
    experimentId,
    qualityScore,
  }: {
    lead?: LeadServicesInfo | null;
    offerCount: number;
    experimentId: string | null;
    qualityScore?: QualityScore;
  }): AdsMetadata => {
    return {
      ageGroup: getAgeGroup(lead?.age || undefined),
      city: lead?.addressCity || undefined,
      gender: lead?.gender?.toLowerCase() || undefined,
      offerCount: offerCount || undefined,
      experiment: experimentId || undefined,
      deviceType: deviceData.type,
      deviceBrand: deviceData.vendor,
      qualityScore: qualityScore?.score.toFixed(4),
    };
  };

  const handleAdSenseReady = () => {
    setIsAdSenseReady(true);
  };

  const handleGPTReady = () => {
    initGPT();
    setIsGPTReady(true);
  };

  return (
    <>
      <AdSenseScript
        adClient={getAdSenseClientId({ siteId: brandId })}
        onLoaded={handleAdSenseReady}
      />

      {renderGPT && <GooglePublisherTagsScript onLoaded={handleGPTReady} />}

      <AdsGlobalContext.Provider
        value={{
          placements: adPlacements,
          isAdSenseReady,
          isGPTReady,
          onAdSlotStatusChange: ({
            adProvider,
            slotId,
            status,
            offerListingId,
          }) => {
            const slotName = getAdSlotName({ slotId }) || "unknown";
            trackAdSlotStatusChange({
              adProvider,
              slotId,
              slotName,
              status,
              offerListingId,
            });
          },
          adSenseClientId: getAdSenseClientId({
            siteId: brandId,
          }),
          adsMetadata,
          initAds: handleInitAds,
        }}
      >
        {children}
      </AdsGlobalContext.Provider>
    </>
  );
};
