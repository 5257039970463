import { branding } from "@domain/branding/common";
import { getCountryFromEntityId } from "@domain/localisation/common/getCountryFromEntityId";
import { JEFF_VN_BRAND_ID } from "@domain/branding/constants";
import { type Country } from "@domain/common-types";
import { allPhonePrefixes } from "../types";
import { idItems } from "./id";
import { inItems } from "./in";
import { phItems } from "./ph";
import { vnItems } from "./vn";
import { mxItems } from "./mx";
import type { Currency, HrefLang, LocalisationConfig } from "../types";
import type { BrandId } from "@domain/branding/common";

const brandIdOverride = process.env.NEXT_PUBLIC_LOCAL_DEV_BRAND_ID as BrandId;
const countryToUse =
  getCountryFromEntityId({
    entityId: brandIdOverride || branding.brandId || JEFF_VN_BRAND_ID,
  }) || "VN";

const currencyByCountryMap: Record<Country, Currency> = {
  ID: idItems.currency,
  IN: inItems.currency,
  PH: phItems.currency,
  US: vnItems.currency,
  VN: vnItems.currency,
  MX: mxItems.currency,
};

const hrefLangByCountryMap: Record<Country, HrefLang> = {
  ID: idItems.hrefLang,
  IN: inItems.hrefLang,
  MX: mxItems.hrefLang,
  PH: phItems.hrefLang,
  US: "en-US",
  VN: vnItems.hrefLang,
};

const localisationConfigItemsByCountryMap: Record<Country, LocalisationConfig> =
  {
    ID: idItems,
    IN: inItems,
    MX: mxItems,
    PH: phItems,
    US: vnItems,
    VN: vnItems,
  };

export const localhostItems: LocalisationConfig = {
  country: countryToUse,
  allowedLanguages: Array.from(
    new Set([
      ...localisationConfigItemsByCountryMap[countryToUse].allowedLanguages,
      "en",
    ]),
  ),
  defaultLanguage:
    localisationConfigItemsByCountryMap[countryToUse].defaultLanguage,
  hrefLang: hrefLangByCountryMap[countryToUse],
  currency: currencyByCountryMap?.[countryToUse],
  phonePrefix: allPhonePrefixes?.[countryToUse],
  locale: localisationConfigItemsByCountryMap[countryToUse].locale,
  dateInputFormat: "dd/MM/yyyy",
  countryName: "countryName",
};
