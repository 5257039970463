import { type ProfileConfig } from "../types";

export const config: ProfileConfig = {
  enabled: true,
  spaces: ["/my-activity", "/level-up"],
  dashboard: { otherProducts: { enabled: false } },
  followUs: { enabled: [] },
  share: {
    enabled: false,
  },
  levelUp: {
    enabled: true,
    collectingPayments: true,
    supportPhone: null,
    paymentType: "one-time",
    showFAQ: false,
    layout: ["rejection-reasons"],
  },
};
