import { LOCALISATION_CONFIG_MAP } from "../localisation-config/localisationConfigMap";
import type { BrandId } from "@domain/branding/common";

type Args = {
  brandId: BrandId;
};

export const fetchLocalisationConfig = ({ brandId }: Args) => {
  const config = LOCALISATION_CONFIG_MAP[brandId];
  if (!config) {
    throw new Error(`No localisation config found for brandId: ${brandId}`);
  }
  return config;
};
