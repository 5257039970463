import { type ProfileConfig } from "../types";

export const config: ProfileConfig = {
  enabled: true,
  spaces: [
    "/dashboard",
    "/my-activity",
    "/me",
    "/level-up",
    "/my-credit-card-ownership",
  ],
  dashboard: {
    otherProducts: { enabled: false },
  },
  followUs: { enabled: ["zalo"] },
  share: {
    enabled: true,
  },
  levelUp: {
    enabled: true,
    collectingPayments: true,
    supportPhone: null,
    paymentType: "one-time",
    showFAQ: true,
    layout: ["rejection-reasons"],
  },
};
