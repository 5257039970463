import React, { createContext, useEffect, useState } from "react";
import { Router, useRouter } from "next/router";
import { isErrorTypeOf, onError } from "@integration/bugsnag/csr";
import { useLeadIdNext } from "@domain/leadId/csr/useLeadIdNext";
import { CircularLoader } from "@components/organisms/CircularLoader";
import type { ReactNode } from "react";
import type { RouteLoadingContextOptions, RouteLoadingContextT } from "./types";

type Props = {
  children: ReactNode;
};

export const RouteLoadingContext = createContext<RouteLoadingContextT>({
  isPageTransitionLoading: false,
  setTransitionState: () => null,
});

export const RouteLoadingContextProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const leadId = useLeadIdNext();
  const { pathname } = useRouter();
  const [isGlobalLoader, setIsGlobalLoader] = useState<boolean>(true);

  const handleSetTransitionVariant = (options: RouteLoadingContextOptions) => {
    if (options) {
      setIsGlobalLoader(!!options.useDefaultLoader);
    }
  };

  useEffect(() => {
    const start = (url: string) => {
      const newPathname = new URL(url, window.location.href).pathname;
      if (newPathname !== pathname) {
        setLoading(true);
      }
    };
    const end = () => {
      setLoading(false);
      setIsGlobalLoader(true);
    };

    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on(
      "routeChangeError",
      (e: Error & { cancelled?: boolean }) => {
        if (!e.cancelled) {
          const ignorable = isErrorTypeOf({
            e,
            errorType: "route-did-not-complete-loading",
          });
          if (ignorable) {
            return;
          }
          if (isErrorTypeOf({ e, errorType: "failed-to-load-static-props" })) {
            e.name = "FailedToLoadStaticProps";
          }
          if (isErrorTypeOf({ e, errorType: "failed-to-load-script" })) {
            e.name = "FailedToLoadScript";
          }
          onError(e, { leadId, pathname });
        }
        end();
      },
    );
    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, [leadId, pathname]);

  return (
    <RouteLoadingContext.Provider
      value={{
        isPageTransitionLoading: loading,
        setTransitionState: handleSetTransitionVariant,
      }}
    >
      <CircularLoader loading={loading} isSpinnerShown={isGlobalLoader} />
      {children}
    </RouteLoadingContext.Provider>
  );
};
