import {
  FINMART_ID_BRAND_ID,
  FINMART_VN_BRAND_ID,
  FINMERCADO_MX_BRAND_ID,
  FINMERKADO_PH_BRAND_ID,
  JEFF_VN_BRAND_ID,
  LOANONLINE_BRAND_ID,
  PAISA247_IN_BRAND_ID,
} from "@domain/branding/constants";
import { idItems } from "./countries/id";
import { vnItems } from "./countries/vn";
import { mxItems } from "./countries/mx";
import { phItems } from "./countries/ph";
import { inItems } from "./countries/in";
import type { LocalisationConfig } from "./types";
import type { BrandId } from "@domain/branding/common";

export const LOCALISATION_CONFIG_MAP: Record<BrandId, LocalisationConfig> = {
  [FINMART_ID_BRAND_ID]: idItems,
  [LOANONLINE_BRAND_ID]: phItems,
  [JEFF_VN_BRAND_ID]: vnItems,
  [FINMART_VN_BRAND_ID]: vnItems,
  [FINMERCADO_MX_BRAND_ID]: mxItems,
  [FINMERKADO_PH_BRAND_ID]: phItems,
  [PAISA247_IN_BRAND_ID]: inItems,
};
