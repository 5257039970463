export const gtmTags = {
  ph: {
    loanonline: "GTM-KG8792N",
    finmerkado: "GTM-WCW4CWCK",
  },
  vn: {
    jeff: "GTM-WJM8NRK",
    finmart: "GTM-MN8MVR2",
  },
  lk: "GTM-572K789",
  in: "G-NS47WZ2BLT",
  id: {
    finmart: "GTM-MWLZ96TT",
  },
  mx: "GTM-P7DC9M2M",
};
