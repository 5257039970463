import {
  JEFF_DOMAIN,
  LOCALHOST_DOMAIN,
  LOANONLINE_DOMAIN,
  JEFF_DEV_DOMAIN,
  LOANONLINE_DEV_DOMAIN,
  FINMART_ID_DOMAIN,
  FINMART_ID_DEV_DOMAIN,
  FINMART_VN_DOMAIN,
  FINMART_VN_DEV_DOMAIN,
  JEFF_WEBFLOW_DOMAIN,
  FINMERCADO_MX_DOMAIN,
  FINMERCADO_MX_DEV_DOMAIN,
  FINMERKADO_PH_DOMAIN,
  FINMERKADO_PH_DEV_DOMAIN,
  PAISA247_IN_DOMAIN,
  PAISA247_IN_DEV_DOMAIN,
} from "../constants/constants";
import { jeffBranding } from "../branding-configs/jeff-vn";
import { localhostBranding } from "../branding-configs/localhost";
import { loanonlineBranding } from "../branding-configs/loanonline";
import { jeffVNDevBranding } from "../branding-configs/jeff-vn-dev";
import { loanonlineDevBranding } from "../branding-configs/loanonline-dev";
import { finmartIdBranding } from "../branding-configs/finmart-id";
import { finmartIdDevBranding } from "../branding-configs/finmart-id-dev";
import { finmartVnBranding } from "../branding-configs/finmart-vn";
import { finmartVnDevBranding } from "../branding-configs/finmart-vn-dev";
import { finmercadoMxBranding } from "../branding-configs/finmercado-mx";
import { finmercadoMxDevBranding } from "../branding-configs/finmercado-mx-dev";
import { finmerkadoPhBranding } from "../branding-configs/finmerkado-ph";
import { finmerkadoPhDevBranding } from "../branding-configs/finmerkado-ph-dev";
import { paisa247Branding } from "../branding-configs/paisa247-in";
import type { Branding, BrandDomains } from "../common/types";

export const domainToBrandingMap: Record<BrandDomains, Branding> = {
  [JEFF_DOMAIN]: jeffBranding,
  [JEFF_WEBFLOW_DOMAIN]: jeffBranding,
  [JEFF_DEV_DOMAIN]: jeffVNDevBranding,
  [LOCALHOST_DOMAIN]: localhostBranding,
  [LOANONLINE_DOMAIN]: loanonlineBranding,
  [LOANONLINE_DEV_DOMAIN]: loanonlineDevBranding,
  [FINMART_ID_DOMAIN]: finmartIdBranding,
  [FINMART_ID_DEV_DOMAIN]: finmartIdDevBranding,
  [FINMART_VN_DOMAIN]: finmartVnBranding,
  [FINMART_VN_DEV_DOMAIN]: finmartVnDevBranding,
  [FINMERCADO_MX_DOMAIN]: finmercadoMxBranding,
  [FINMERCADO_MX_DEV_DOMAIN]: finmercadoMxDevBranding,
  [FINMERKADO_PH_DOMAIN]: finmerkadoPhBranding,
  [FINMERKADO_PH_DEV_DOMAIN]: finmerkadoPhDevBranding,
  [PAISA247_IN_DOMAIN]: paisa247Branding,
  [PAISA247_IN_DEV_DOMAIN]: paisa247Branding,
};
