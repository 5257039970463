import {
  EmploymentOccupation as EmploymentOccupationApiType,
  EmploymentIndustry as EmploymentIndustryApiType,
  IdDocumentType as IdDocumentTypeApiType,
  LoanType as LoanTypeApiType,
  LoanPurpose as LoanPurposeApiType,
  LoanUrgency as LoanUrgencyApiType,
  SalaryCycle as SalaryCycleApiType,
} from "@jeff/lead-api";
import { z } from "zod";
import { getEnumValues } from "@utils/enums";

const CollateralInputEntrySchema = z.object({
  collateralType: z.string(),
  owner: z.boolean(),
  present: z.boolean(),
});

const CollateralInputSchema = z.object({
  entries: z.array(CollateralInputEntrySchema),
});

const EmploymentIndustrySchema = z.enum(
  getEnumValues(EmploymentIndustryApiType),
);

const EmploymentOccupationSchema = z.enum(
  getEnumValues(EmploymentOccupationApiType),
);

const IdDocumentTypeSchema = z.enum(getEnumValues(IdDocumentTypeApiType));

const LoanTypeSchema = z.enum(getEnumValues(LoanTypeApiType));

export const LoanPurposeSchema = z.enum(getEnumValues(LoanPurposeApiType));

export type LoanPurposeSchemaType = z.infer<typeof LoanPurposeSchema>;

const LoanUrgencySchema = z.enum(getEnumValues(LoanUrgencyApiType));

const SalaryCycleSchema = z.enum(getEnumValues(SalaryCycleApiType));

const TermRangeInputSchema = z.object({
  from: z.number().int().optional(),
  to: z.number().int().optional(),
  unit: z.string(),
});

export const LoanApplicationInputSchema = z.object({
  addressCity: z.string().optional(),
  addressDistrict: z.string().optional(),
  addressHouseNumber: z.string().optional(),
  addressLine: z.string().optional(),
  addressPostalCode: z.string().optional(),
  addressProvince: z.string().optional(),
  addressRegion: z.string().optional(),
  addressStreet: z.string().optional(),
  addressWard: z.string().optional(),
  bankAccount: z.string().optional(),
  bankAccountPresenceConfirmed: z
    .object({
      value: z.boolean(),
    })
    .optional(),
  civilStatus: z.string().optional(),
  collateralTypes: z.array(z.string()).optional(),
  collaterals: CollateralInputSchema.optional(),
  dateOfBirth: z.string().optional(),
  employerName: z.string().optional(),
  employerPhoneNumber: z.string().optional(),
  employmentIndustry: EmploymentIndustrySchema.optional(),
  employmentOccupation: EmploymentOccupationSchema.optional(),
  employmentStatus: z.string().optional(),
  firstName: z.string().optional(),
  fullName: z.string().optional(),
  gender: z.string().optional(),
  hasBeneficiary: z.boolean().optional(),
  hasCollaterals: z.boolean().optional(),
  hasCreditCard: z.boolean().optional(),
  hasIncomeStatement: z.boolean().optional(),
  hasLocalCitizenship: z.boolean().optional(),
  hasPoliticalExposure: z.boolean().optional(),
  hasRecentLoan: z.boolean().optional(),
  hasRecentLoanOverdue: z.boolean().optional(),
  hasRecentLoanPaid: z.boolean().optional(),
  hasSocialInsurance: z.boolean().optional(),
  idDocumentIssueDate: z.string().optional(),
  idDocumentNumber: z.string().optional(),
  idDocumentType: IdDocumentTypeSchema.optional(),
  incomeOfficial: z.boolean().optional(),
  isCompleted: z.boolean().optional(),
  lastName: z.string().optional(),
  lastNameSecondary: z.string().optional(),
  leadPreferredLoanTypes: z.array(LoanTypeSchema).optional(),
  loanAmount: z.number().optional(),
  loanPurpose: LoanPurposeSchema.optional(),
  loanPurposeType: LoanPurposeSchema.optional(),
  loanTermInDays: z.number().optional(),
  loanTermInMonths: z.number().optional(),
  loanUrgency: LoanUrgencySchema.optional(),
  locationOfBirth: z.string().optional(),
  middleName: z.string().optional(),
  monthlyIncome: z.number().optional(),
  personalId: z.string().optional(),
  salaryCycle: SalaryCycleSchema.optional(),
  salaryCycleValue: z.string().optional(),
  term: TermRangeInputSchema.optional(),
});

export type LoanApplicationInputSchemaType = z.infer<
  typeof LoanApplicationInputSchema
>;
