import { createContext } from "react";
import { type Country } from "@domain/common-types";
import { type TranslateFunction } from "../types";
import type { Locale } from "date-fns";
import type {
  Currency,
  DateInputFormat,
  HrefLang,
  HumanReadableLanguage,
  Languages,
  PhonePrefix,
} from "@domain/localisation/localisation-config/types";

export interface LocalisationContextProps {
  t: TranslateFunction;
  currentLang: Languages;
  allowedLanguages: Languages[];
  allowedHumanReadableLanguages: HumanReadableLanguage[];
  humanReadableLang: HumanReadableLanguage;
  hrefLang: HrefLang;
  setCurrentLang: (newLanguage: Languages) => void;
  currency: Currency;
  phonePrefix: PhonePrefix;
  locale: Locale;
  dateInputFormat: DateInputFormat;
  country: Country;
  countryName: string;
}

export const LocalisationContext = createContext<LocalisationContextProps>(
  {} as LocalisationContextProps,
);
