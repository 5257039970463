import {
  ASIA_DEALS_BRAND_ID,
  DINEROEXPRES_MX_BRAND_ID,
  DONG247_BRAND_ID,
  DONG_DEALS_BRAND_ID,
  FINMERCADO_MX_COOKIE_DOMAIN,
  JULO_ID_BRAND_ID,
  KIMBI_MX_BRAND_ID,
  MONEYDONG_BRAND_ID,
  PEROLOAN_BRAND_ID,
  TUNAIKU_ID_BRAND_ID,
} from "@domain/branding/constants";
import {
  PARAM_NAME_LEAD_PAGE,
  PARAM_NAME_PARTNER_URL_KEY,
  PARAM_NAME_REDIRECT_TO_LEAD_FORM_LANDING,
} from "@utils/constants";
import type { UrlParams } from "@integration/query-params/common/types";
import type { LeadPageConfig, LeadPageId } from "./types";

/**
 * please refer to
 * apps/embeddable-scripts/src/webflow/decorate-links.ts
 * APPS list should match these configEntries
 */
const configEntries: LeadPageConfig[] = [
  {
    id: DONG247_BRAND_ID,
    rootDomain: "dong247.com",
    targetRootDomain: "jeff.vn",
  },
  {
    id: ASIA_DEALS_BRAND_ID,
    rootDomain: "asia-deals.com",
    targetRootDomain: "jeff.vn",
  },
  {
    id: DONG_DEALS_BRAND_ID,
    rootDomain: "dong-deals.com",
    targetRootDomain: "jeff.vn",
  },
  {
    id: MONEYDONG_BRAND_ID,
    rootDomain: "moneydong.com",
    targetRootDomain: "jeff.vn",
  },
  {
    id: PEROLOAN_BRAND_ID,
    rootDomain: "peroloan.ph",
    targetRootDomain: "loanonline.ph",
  },
  {
    id: JULO_ID_BRAND_ID,
    rootDomain: "julo.co.id",
    targetRootDomain: "finmart.id",
  },
  {
    id: TUNAIKU_ID_BRAND_ID,
    rootDomain: "tunaiku.com",
    targetRootDomain: "finmart.id",
  },
  {
    id: DINEROEXPRES_MX_BRAND_ID,
    rootDomain: "dineroexpres.mx",
    targetRootDomain: "finmercado.mx",
  },
  {
    id: KIMBI_MX_BRAND_ID,
    rootDomain: "kimbi.mx",
    targetRootDomain: FINMERCADO_MX_COOKIE_DOMAIN,
  },
];

export const isComingFromLeadPage = ({
  urlParams,
}: {
  urlParams: UrlParams;
}): boolean => {
  if (
    urlParams[PARAM_NAME_LEAD_PAGE] === "true" ||
    urlParams[PARAM_NAME_REDIRECT_TO_LEAD_FORM_LANDING] === "true"
  ) {
    return true;
  }
  const partnerUrlKey = urlParams[PARAM_NAME_PARTNER_URL_KEY];
  if (partnerUrlKey) {
    const entry = configEntries.find((it) => it.id === partnerUrlKey);
    return entry !== undefined;
  }
  return false;
};

export const asLeadPageId = ({
  input,
}: {
  input: string | undefined;
}): LeadPageId | undefined => {
  if (!input) {
    return;
  }
  return configEntries.find((it) => it.id === input.trim().split(",")[0])?.id;
};
