import { type Branding } from "../common";
import {
  PAISA247_IN_BRAND_ID,
  PAISA247_IN_BRAND_NAME,
  PAISA247_IN_COOKIE_DOMAIN,
  PAISA247_IN_DOMAIN,
  PAISA247_IN_HOMEPAGE,
} from "../constants";
import { gtmTags } from "./gtmTags";

export const paisa247Branding: Branding = {
  brandId: PAISA247_IN_BRAND_ID,
  brandName: PAISA247_IN_BRAND_NAME,
  cookieDomain: PAISA247_IN_COOKIE_DOMAIN,
  brandDomain: PAISA247_IN_DOMAIN,
  homepage: PAISA247_IN_HOMEPAGE,
  gtmId: gtmTags.in,
  tncLink: "https://www.paisa247.in/terms-and-conditions",
  privacyPolicyLink: "https://www.paisa247.in/privacy-policy",
};
