import { getProfileConfig } from "@domain/profile";
import { PAISA247_IN_BRAND_ID } from "@domain/branding/constants";
import type { ProductConfig } from "@domain/product-config/types";

export const paisa247InConfig: ProductConfig = {
  countryCode: "IN",
  socialProviderConfig: {
    enabledSocialProviders: [],
  },
  profileConfig: {
    ...getProfileConfig({ brandId: PAISA247_IN_BRAND_ID }),
  },
  flowConfig: {
    isUnifiedFlowEnabled: true,
  },
  adSense: {
    enabled: false,
  },
  userFeedback: {
    canShowFeedbackInOfferCards: false,
  },
  otp: {
    enabled: false,
  },
  captcha: {
    enabled: false,
  },
  olFeedbackPopUp: {
    enabled: false,
  },
};
