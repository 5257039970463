import { type BrandId } from "@domain/branding/common";
import {
  FINMART_VN_BRAND_ID,
  FINMERCADO_MX_BRAND_ID,
  FINMERKADO_PH_BRAND_ID,
  JEFF_VN_BRAND_ID,
  LOANONLINE_BRAND_ID,
  PAISA247_IN_BRAND_ID,
} from "@domain/branding/constants";
import { type ProfileConfig } from "../types";
import { config as jeffVN } from "./vn-jeff";
import { config as finmartVN } from "./vn-finmart";
import { config as loanonlinePH } from "./ph-loanonline";
import { config as finmerkadoPH } from "./ph-finmerkado";
import { config as finmercadoMX } from "./mx-finmercado";
import { config as paisa247IN } from "./in-paisa247";

export const getProfileConfig = ({
  brandId,
}: {
  brandId: BrandId;
}): ProfileConfig => {
  if (brandId === JEFF_VN_BRAND_ID) {
    return jeffVN;
  }
  if (brandId === FINMART_VN_BRAND_ID) {
    return finmartVN;
  }
  if (brandId === LOANONLINE_BRAND_ID) {
    return loanonlinePH;
  }
  if (brandId === FINMERKADO_PH_BRAND_ID) {
    return finmerkadoPH;
  }
  if (brandId === FINMERCADO_MX_BRAND_ID) {
    return finmercadoMX;
  }
  if (brandId === PAISA247_IN_BRAND_ID) {
    return paisa247IN;
  }
  return {
    enabled: false,
    spaces: [],
    dashboard: { otherProducts: { enabled: false } },
    followUs: { enabled: [] },
    share: {
      enabled: false,
    },
  };
};
