export const FEEDBACK_ALWAYS_OPEN = "feedback-always-open"; // used by tests
export const LOTTERY_FEATURE = "lottery";
export const LEVEL_UP_FEATURE_ENABLED_FEATURE = "level-up-feature-enabled";
export const LEVEL_UP_PROFILE_DASHBOARD_BANNER_FEATURE =
  "level-up-profile-dashboard-banner";
export const MONEYMAN_AUTO_REDIRECT_FEATURE = "moneymay-auto-redirect-feature";
export const FINBRO_AUTO_REDIRECT_FEATURE = "finbro-auto-redirect-feature";
export const DOCTOR_DONG_REDIRECT_FEATURE = "doctor-dong-auto-redirect-feature";
export const SHOW_DYNAMIC_BANNER_OFFER_LISTING =
  "show-dynamic-banner-offer-listing";
export const LEVEL_UP_OFFER_LISTING_BANNER_FEATURE =
  "level-up-offer-listing-banner";
export const EXTENDED_MX_AUTO_REDIRECT_FEATURE =
  "extended-mx-auto-redirect-feature";
export const ACTIVITY_LISTING_IN_OFFERS_FEATURE = "activity-listing-in-offers";
export const OL_FEEDBACK_POP_UP_FEATURE = "ol-feedback-pop-up";

export const featureList = [
  FEEDBACK_ALWAYS_OPEN,
  LOTTERY_FEATURE,
  LEVEL_UP_FEATURE_ENABLED_FEATURE,
  LEVEL_UP_PROFILE_DASHBOARD_BANNER_FEATURE,
  MONEYMAN_AUTO_REDIRECT_FEATURE,
  FINBRO_AUTO_REDIRECT_FEATURE,
  DOCTOR_DONG_REDIRECT_FEATURE,
  SHOW_DYNAMIC_BANNER_OFFER_LISTING,
  LEVEL_UP_OFFER_LISTING_BANNER_FEATURE,
  EXTENDED_MX_AUTO_REDIRECT_FEATURE,
  ACTIVITY_LISTING_IN_OFFERS_FEATURE,
  OL_FEEDBACK_POP_UP_FEATURE,
] as const;

export const SPLIT_CHAR = ">";
