export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: number;
  Long: number;
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['ID'];
  lead: Lead;
};

export type AddDeviceInput = {
  leadId: Scalars['String'];
  platform: Scalars['String'];
  token: Scalars['String'];
};

export type AddMyApplicationFeedbackRequest = {
  activityOfferId: Scalars['String'];
  additionalComments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  additionalReviewTags?: InputMaybe<Array<Scalars['String']>>;
  applicationProcess?: InputMaybe<FeedbackRating>;
  comment?: InputMaybe<Scalars['String']>;
  easeOfReceivingLoan?: InputMaybe<FeedbackRating>;
  loanDueDate?: InputMaybe<Scalars['String']>;
  loanIssued?: InputMaybe<Scalars['Boolean']>;
  personalExperience?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<FeedbackRating>;
  recommendLender?: InputMaybe<Scalars['Boolean']>;
  reviewTags?: InputMaybe<Array<Scalars['String']>>;
  setPaymentReminder?: InputMaybe<Scalars['Boolean']>;
  timeToLoan?: InputMaybe<Scalars['String']>;
};

export type AddMyApplicationLoanRepaymentDate = {
  activityOfferId: Scalars['String'];
  dueDate: Scalars['String'];
};

export type AddPartnerRegistrationConsentsInput = {
  companies?: InputMaybe<Array<Scalars['String']>>;
};

export type AdditionalFeatureInput = {
  screenHeight?: InputMaybe<Scalars['Int']>;
  screenWidth?: InputMaybe<Scalars['Int']>;
};

export type ApplicationRequest = {
  ewallet?: InputMaybe<EWalletApplicationRequest>;
  insurance?: InputMaybe<InsuranceApplicationRequest>;
  nearPrime?: InputMaybe<NearPrimeApplicationRequest>;
  shortTermLoan?: InputMaybe<ShortTermLoanApplicationRequest>;
};

export type BooleanFieldInput = {
  value: Scalars['Boolean'];
};

export enum CivilStatus {
  Divorced = 'DIVORCED',
  Married = 'MARRIED',
  Single = 'SINGLE',
  Widowed = 'WIDOWED'
}

export type ClaimLoyaltyPointsResponse = {
  __typename?: 'ClaimLoyaltyPointsResponse';
  points: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type CollateralInput = {
  entries: Array<CollateralInputEntry>;
};

export type CollateralInputEntry = {
  collateralType: Scalars['String'];
  owner: Scalars['Boolean'];
  present: Scalars['Boolean'];
};

export type Company = {
  __typename?: 'Company';
  displayName: Scalars['String'];
};

export type ConfirmInsurancePolicyInput = {
  interactionId: Scalars['String'];
  offerId: Scalars['String'];
};

export type CreateAccountInput = {
  firstName?: InputMaybe<Scalars['String']>;
  leadId: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type CreateInsuranceInteractionInput = {
  leadId: Scalars['String'];
};

export type CreateLeadIntentInput = {
  anonymousReviewId?: InputMaybe<Scalars['String']>;
  application?: InputMaybe<ApplicationRequest>;
  autofill?: InputMaybe<Array<Param>>;
  captchaToken?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  leadPageId?: InputMaybe<Scalars['String']>;
  marketing?: InputMaybe<MarketingDataInput>;
  marketingConsent?: InputMaybe<MarketingConsent>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  productType?: InputMaybe<Scalars['String']>;
  publisherData?: InputMaybe<LeadPublisherData>;
  selectedProduct?: InputMaybe<SelectedProductInput>;
};

export type CreditCardBonusItem = {
  __typename?: 'CreditCardBonusItem';
  content?: Maybe<Scalars['String']>;
  key: Scalars['String'];
};

export type CreditCardEligibility = {
  __typename?: 'CreditCardEligibility';
  minAnnualIncome: Scalars['Int'];
};

export type CreditCardFees = {
  __typename?: 'CreditCardFees';
  annualFee: Scalars['Int'];
  annualFeeWaiver: Scalars['Boolean'];
  issuance: Scalars['Int'];
  withdrawalText?: Maybe<Scalars['String']>;
};

export type CreditCardInterest = {
  __typename?: 'CreditCardInterest';
  interestFreePeriod: Scalars['Int'];
  interestRateText: Scalars['String'];
};

export type CreditCardLimits = {
  __typename?: 'CreditCardLimits';
  maxCreditLimit?: Maybe<Scalars['Long']>;
  minCreditLimit?: Maybe<Scalars['Long']>;
  withdrawalLimitText: Scalars['String'];
};

export type CreditCardOfferDetails = {
  __typename?: 'CreditCardOfferDetails';
  association: Scalars['String'];
  bonuses: Array<CreditCardBonusItem>;
  discriminator: Scalars['String'];
  displayName: Scalars['String'];
  eligibility: CreditCardEligibility;
  fees: CreditCardFees;
  interest: CreditCardInterest;
  limits: CreditCardLimits;
  shortDescription?: Maybe<Scalars['String']>;
};

export type EWalletApplicationRequest = {
  isEWalletApplication: Scalars['Boolean'];
};

export type EmailStatus = {
  __typename?: 'EmailStatus';
  email: Scalars['String'];
  failed: Scalars['Boolean'];
  manualConfirmationNeeded: Scalars['Boolean'];
  processing: Scalars['Boolean'];
  suggestion?: Maybe<Scalars['String']>;
};

export type EmailValidationData = {
  __typename?: 'EmailValidationData';
  emailValidationResponse?: Maybe<EmailValidationResponse>;
  existingEmail: Scalars['Boolean'];
};

export type EmailValidationResponse = {
  __typename?: 'EmailValidationResponse';
  suggestion?: Maybe<Scalars['String']>;
  valid: Scalars['Boolean'];
};

export enum EmploymentIndustry {
  AdministrationOffice = 'ADMINISTRATION_OFFICE',
  AgricultureForestry = 'AGRICULTURE_FORESTRY',
  AirTransportation = 'AIR_TRANSPORTATION',
  Automotive = 'AUTOMOTIVE',
  Chemistry = 'CHEMISTRY',
  Construction = 'CONSTRUCTION',
  Culture = 'CULTURE',
  CustomsMaritimeTransport = 'CUSTOMS_MARITIME_TRANSPORT',
  DebtCollection = 'DEBT_COLLECTION',
  Education = 'EDUCATION',
  EducationResearch = 'EDUCATION_RESEARCH',
  Engineering = 'ENGINEERING',
  FinanceAccounting = 'FINANCE_ACCOUNTING',
  FinancialServices = 'FINANCIAL_SERVICES',
  FoodEntertainment = 'FOOD_ENTERTAINMENT',
  ForestryWood = 'FORESTRY_WOOD',
  GovernmentPublicService = 'GOVERNMENT_PUBLIC_SERVICE',
  Healthcare = 'HEALTHCARE',
  HealthcareSocialServices = 'HEALTHCARE_SOCIAL_SERVICES',
  HotelsAndTourism = 'HOTELS_AND_TOURISM',
  ItSoftware = 'IT_SOFTWARE',
  ManufacturingProductionConstruction = 'MANUFACTURING_PRODUCTION_CONSTRUCTION',
  Media = 'MEDIA',
  MetalProduction = 'METAL_PRODUCTION',
  Military = 'MILITARY',
  Mining = 'MINING',
  NailHairCare = 'NAIL_HAIR_CARE',
  OilAndGas = 'OIL_AND_GAS',
  Other = 'OTHER',
  Police = 'POLICE',
  PostAndTelecommunicationsService = 'POST_AND_TELECOMMUNICATIONS_SERVICE',
  ProfessionalServices = 'PROFESSIONAL_SERVICES',
  RealEstate = 'REAL_ESTATE',
  SalesMarketingCustomerServices = 'SALES_MARKETING_CUSTOMER_SERVICES',
  Textiles = 'TEXTILES',
  Tobacco = 'TOBACCO',
  Trade = 'TRADE',
  TransportationLogistics = 'TRANSPORTATION_LOGISTICS'
}

export enum EmploymentOccupation {
  BlueCollar = 'BLUE_COLLAR',
  BusinessOwner = 'BUSINESS_OWNER',
  BusinessProcessOutsourcing = 'BUSINESS_PROCESS_OUTSOURCING',
  Driver = 'DRIVER',
  Employed = 'EMPLOYED',
  Farmer = 'FARMER',
  ForeignResident = 'FOREIGN_RESIDENT',
  Freelancer = 'FREELANCER',
  Housewife = 'HOUSEWIFE',
  Journalist = 'JOURNALIST',
  Lawyer = 'LAWYER',
  MilitaryPersonnel = 'MILITARY_PERSONNEL',
  Other = 'OTHER',
  OverseasWorker = 'OVERSEAS_WORKER',
  Police = 'POLICE',
  PrivateSector = 'PRIVATE_SECTOR',
  PublicSector = 'PUBLIC_SECTOR',
  Retired = 'RETIRED',
  SelfEmployed = 'SELF_EMPLOYED',
  Student = 'STUDENT',
  Unemployed = 'UNEMPLOYED',
  WhiteCollar = 'WHITE_COLLAR'
}

export enum FeedbackActivityStatus {
  ApplicationApproved = 'APPLICATION_APPROVED',
  ApplicationRejected = 'APPLICATION_REJECTED',
  WaitingForResponse = 'WAITING_FOR_RESPONSE'
}

export type FeedbackAggregatedSummary = {
  __typename?: 'FeedbackAggregatedSummary';
  activeTags: Array<Scalars['String']>;
  applicationRating: Scalars['Float'];
  averageRating: Scalars['Float'];
  companyId: Scalars['String'];
  easeOfReceivingLoanRating: Scalars['Float'];
  isMostPopular: Scalars['Boolean'];
  isTop3: Scalars['Boolean'];
  offerId: Scalars['String'];
  ratingCount: Scalars['Int'];
};

export type FeedbackFilters = {
  cities?: InputMaybe<FilterByCities>;
  ratings?: InputMaybe<FilterByRatings>;
};

/** Review/Feedback sub-domain, Nested domain */
export enum FeedbackRating {
  RatingStar_1 = 'RATING_STAR_1',
  RatingStar_2 = 'RATING_STAR_2',
  RatingStar_3 = 'RATING_STAR_3',
  RatingStar_4 = 'RATING_STAR_4',
  RatingStar_5 = 'RATING_STAR_5'
}

export type FilterByCities = {
  values: Array<Scalars['String']>;
};

export type FilterByRatings = {
  values: Array<Scalars['String']>;
};

export type FinbroConversionResponse = {
  __typename?: 'FinbroConversionResponse';
  converted: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
};

export type FinbroOtpResponse = {
  __typename?: 'FinbroOtpResponse';
  linkWhenAlreadyConfirmed?: Maybe<Scalars['String']>;
  sent: Scalars['Boolean'];
};

export enum IdDocumentType {
  AadhaarCard = 'AADHAAR_CARD',
  DriversLicence = 'DRIVERS_LICENCE',
  PanCard = 'PAN_CARD',
  Passport = 'PASSPORT',
  SocialSecuritySystemId = 'SOCIAL_SECURITY_SYSTEM_ID',
  TaxIdentificationNumber = 'TAX_IDENTIFICATION_NUMBER',
  UnifiedMultiPurposeId = 'UNIFIED_MULTI_PURPOSE_ID'
}

export type Image = {
  __typename?: 'Image';
  format: Scalars['String'];
  path: Scalars['String'];
};

export type InitPaymentResponse = {
  __typename?: 'InitPaymentResponse';
  paymentGateway: Scalars['String'];
  paymentUrl: Scalars['String'];
  price: Scalars['Float'];
};

export type InitiateInsurancePaymentInput = {
  interactionId: Scalars['String'];
  paymentMethod: Scalars['String'];
  redirectUrl: Scalars['String'];
};

export type InsuranceApplicationRequest = {
  insuranceType: Scalars['String'];
};

export type InsuranceCertificate = {
  __typename?: 'InsuranceCertificate';
  barCode?: Maybe<Scalars['String']>;
  policyNumber: Scalars['String'];
  viewOnlinePolicyUrl?: Maybe<Scalars['String']>;
};

export type InsuranceInteraction = {
  __typename?: 'InsuranceInteraction';
  confirmed?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  interactionId: Scalars['String'];
  payment?: Maybe<InsurancePayment>;
  phoneNumber?: Maybe<Scalars['String']>;
  policy?: Maybe<InsurancePolicy>;
  startDate?: Maybe<Scalars['String']>;
  termInYears?: Maybe<Scalars['Int']>;
};

export type InsuranceOffer = {
  __typename?: 'InsuranceOffer';
  id: Scalars['String'];
  partnerId: Scalars['String'];
  totalPrice: Scalars['Float'];
};

export type InsuranceOfferPollingResult = {
  __typename?: 'InsuranceOfferPollingResult';
  items?: Maybe<Array<InsuranceOffer>>;
  ready: Scalars['Boolean'];
};

export type InsurancePayment = {
  __typename?: 'InsurancePayment';
  markFailedForTests?: Maybe<Scalars['String']>;
  markPaidForTests?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type InsurancePolicy = {
  __typename?: 'InsurancePolicy';
  certificate?: Maybe<InsuranceCertificate>;
  created: Scalars['String'];
  paid?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['String']>;
};

export type InsurancePolicyStatusPollingResult = {
  __typename?: 'InsurancePolicyStatusPollingResult';
  policyStatus: PolicyStatus;
  ready: Scalars['Boolean'];
};

export enum IntegrationType {
  MarketingApi = 'MARKETING_API',
  Redirect = 'REDIRECT',
  S2S = 'S2S'
}

export type IntentLead = {
  __typename?: 'IntentLead';
  fresh: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type InterestRate = {
  __typename?: 'InterestRate';
  avgDaily: Scalars['Float'];
  monthly?: Maybe<Scalars['Float']>;
};

export type InterestRateInput = {
  fixedFee?: InputMaybe<Scalars['BigDecimal']>;
  rate: Scalars['BigDecimal'];
  ratePeriod: InterestRatePeriod;
};

export enum InterestRatePeriod {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type Lead = {
  __typename?: 'Lead';
  acceptingFeedback: Scalars['Boolean'];
  accountCreated: Scalars['Boolean'];
  countryCode?: Maybe<Scalars['String']>;
  /**
   * Amount of dong credit lead earned through referrals
   * @deprecated https://jeff-app.atlassian.net/browse/PROD-2894
   */
  earnBalance: Scalars['Float'];
  emailConfirmationRequiredAccountAccess: Scalars['Boolean'];
  emailConfirmed?: Maybe<Scalars['String']>;
  emailConfirmedBy?: Maybe<Scalars['String']>;
  fresh: Scalars['Boolean'];
  hasRedirects: Scalars['Boolean'];
  levelUpPaid: Scalars['Boolean'];
  loyalty: Loyalty;
  nearPrimeFormCompleted: Scalars['Boolean'];
  obfuscatedEmail?: Maybe<Scalars['String']>;
  obfuscatedPhoneNumber?: Maybe<Scalars['String']>;
  phoneNumberConfirmed?: Maybe<Scalars['String']>;
  phoneNumberPresent: Scalars['Boolean'];
  /**
   * Number of referrals left for compensation this month, upper capped by N
   * @deprecated https://jeff-app.atlassian.net/browse/PROD-2894
   */
  referralsLeftThisMonth: Scalars['Int'];
  uuid: Scalars['ID'];
  validForAutoRedirect: Scalars['Boolean'];
  verificationRequired: Scalars['Boolean'];
};

export type LeadAccountStatus = {
  __typename?: 'LeadAccountStatus';
  accessedViaSocialAccount: Scalars['Boolean'];
  accountPasswordPresent: Scalars['Boolean'];
  accountPresent: Scalars['Boolean'];
  addressPresent: Scalars['Boolean'];
  collateralsPresent: Scalars['Boolean'];
  employmentPresent: Scalars['Boolean'];
  nameAndDoBPresent: Scalars['Boolean'];
  phoneConfirmationOffered: Scalars['Boolean'];
  socialInsuranceStatusPresent: Scalars['Boolean'];
};

export type LeadForPartnerOfferStatus = {
  __typename?: 'LeadForPartnerOfferStatus';
  lead?: Maybe<Lead>;
  redirectAllowed: Scalars['Boolean'];
};

export type LeadIntentStatus = {
  __typename?: 'LeadIntentStatus';
  emailStatus?: Maybe<EmailStatus>;
  intentId: Scalars['ID'];
  lead?: Maybe<IntentLead>;
  leadPresent: Scalars['Boolean'];
  partnerConsentStep: PartnerConsentStep;
};

export type LeadPublisherData = {
  publisherClickId: Scalars['String'];
  publisherCustomParams?: InputMaybe<Array<PublisherCustomParam>>;
  publisherName: Scalars['String'];
};

export type LeadS2sResponse = {
  __typename?: 'LeadS2sResponse';
  resultType: Scalars['String'];
};

export type LeadUpdateStatus = {
  __typename?: 'LeadUpdateStatus';
  emailConflict: Scalars['Boolean'];
  emailPresent: Scalars['Boolean'];
  emailValidationResponse?: Maybe<EmailValidationResponse>;
  lead?: Maybe<Lead>;
  phonePresent: Scalars['Boolean'];
};

export type LeaveFeedbackInput = {
  message: Scalars['String'];
};

export enum LineOfBusiness {
  ECommerce = 'E_COMMERCE',
  EWallet = 'E_WALLET',
  Insurance = 'INSURANCE',
  Investing = 'INVESTING',
  Lending = 'LENDING'
}

export type LoanAmount = {
  __typename?: 'LoanAmount';
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type LoanApplicationInput = {
  addressCity?: InputMaybe<Scalars['String']>;
  addressDistrict?: InputMaybe<Scalars['String']>;
  addressHouseNumber?: InputMaybe<Scalars['String']>;
  addressLine?: InputMaybe<Scalars['String']>;
  addressPostalCode?: InputMaybe<Scalars['String']>;
  addressProvince?: InputMaybe<Scalars['String']>;
  addressRegion?: InputMaybe<Scalars['String']>;
  addressStreet?: InputMaybe<Scalars['String']>;
  addressWard?: InputMaybe<Scalars['String']>;
  bankAccount?: InputMaybe<Scalars['String']>;
  bankAccountPresenceConfirmed?: InputMaybe<BooleanFieldInput>;
  civilStatus?: InputMaybe<CivilStatus>;
  collateralTypes?: InputMaybe<Array<Scalars['String']>>;
  collaterals?: InputMaybe<CollateralInput>;
  creditCardPartnerCompanyIds?: InputMaybe<Array<Scalars['String']>>;
  creditCardTenureFromMonths?: InputMaybe<Scalars['Int']>;
  creditCardTenureToMonths?: InputMaybe<Scalars['Int']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  employerName?: InputMaybe<Scalars['String']>;
  employerPhoneNumber?: InputMaybe<Scalars['String']>;
  employmentIndustry?: InputMaybe<EmploymentIndustry>;
  employmentOccupation?: InputMaybe<EmploymentOccupation>;
  employmentStatus?: InputMaybe<Scalars['String']>;
  employmentTenureFromMonths?: InputMaybe<Scalars['Int']>;
  employmentTenureToMonths?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  hasBeneficiary?: InputMaybe<Scalars['Boolean']>;
  hasCollaterals?: InputMaybe<Scalars['Boolean']>;
  hasCreditCard?: InputMaybe<Scalars['Boolean']>;
  hasIncomeStatement?: InputMaybe<Scalars['Boolean']>;
  hasLandlinePhone?: InputMaybe<Scalars['Boolean']>;
  hasLocalCitizenship?: InputMaybe<Scalars['Boolean']>;
  hasPoliticalExposure?: InputMaybe<Scalars['Boolean']>;
  hasRecentLoan?: InputMaybe<Scalars['Boolean']>;
  hasRecentLoanOverdue?: InputMaybe<Scalars['Boolean']>;
  hasRecentLoanPaid?: InputMaybe<Scalars['Boolean']>;
  hasSocialInsurance?: InputMaybe<Scalars['Boolean']>;
  idDocumentIssueDate?: InputMaybe<Scalars['String']>;
  idDocumentNumber?: InputMaybe<Scalars['String']>;
  idDocumentType?: InputMaybe<IdDocumentType>;
  incomeOfficial?: InputMaybe<Scalars['Boolean']>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastNameSecondary?: InputMaybe<Scalars['String']>;
  leadPreferredLoanTypes?: InputMaybe<Array<LoanType>>;
  loanAmount?: InputMaybe<Scalars['Long']>;
  loanPurpose?: InputMaybe<Scalars['String']>;
  loanPurposeType?: InputMaybe<LoanPurpose>;
  loanTermInDays?: InputMaybe<Scalars['Long']>;
  loanTermInMonths?: InputMaybe<Scalars['Long']>;
  loanUrgency?: InputMaybe<LoanUrgency>;
  locationOfBirth?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  monthlyIncome?: InputMaybe<Scalars['Long']>;
  personalId?: InputMaybe<Scalars['String']>;
  salaryCycle?: InputMaybe<SalaryCycle>;
  salaryCycleValue?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<TermRangeInput>;
};

export type LoanApplicationStatus = {
  __typename?: 'LoanApplicationStatus';
  addressCity: Scalars['Boolean'];
  addressDistrict: Scalars['Boolean'];
  addressHouseNumber: Scalars['Boolean'];
  addressLine: Scalars['Boolean'];
  addressPostalCode: Scalars['Boolean'];
  addressProvince: Scalars['Boolean'];
  addressRegion: Scalars['Boolean'];
  addressStreet: Scalars['Boolean'];
  addressWard: Scalars['Boolean'];
  bankAccount: Scalars['Boolean'];
  bankAccountPresenceConfirmed?: Maybe<Scalars['Boolean']>;
  civilStatus: Scalars['Boolean'];
  collateralTypes: Scalars['Boolean'];
  collateralsCompleted: Scalars['Boolean'];
  completed: Scalars['Boolean'];
  creditCardPartnerCompanyIds: Scalars['Boolean'];
  creditCardTenureFromMonths: Scalars['Boolean'];
  creditCardTenureToMonths: Scalars['Boolean'];
  dateOfBirth: Scalars['Boolean'];
  employerName: Scalars['Boolean'];
  employerPhoneNumber: Scalars['Boolean'];
  employmentIndustry: Scalars['Boolean'];
  employmentOccupation: Scalars['Boolean'];
  employmentStatus: Scalars['Boolean'];
  employmentTenureFromMonths: Scalars['Boolean'];
  employmentTenureToMonths: Scalars['Boolean'];
  firstName: Scalars['Boolean'];
  fullName: Scalars['Boolean'];
  gender: Scalars['Boolean'];
  hasBeneficiary: Scalars['Boolean'];
  hasCollaterals: Scalars['Boolean'];
  hasCreditCard: Scalars['Boolean'];
  hasIncomeStatement: Scalars['Boolean'];
  hasLandlinePhone: Scalars['Boolean'];
  hasLocalCitizenship: Scalars['Boolean'];
  hasPoliticalExposure: Scalars['Boolean'];
  hasRecentLoan: Scalars['Boolean'];
  hasRecentLoanOverdue: Scalars['Boolean'];
  hasRecentLoanPaid: Scalars['Boolean'];
  hasSocialInsurance: Scalars['Boolean'];
  idDocumentIssueDate: Scalars['Boolean'];
  idDocumentNumber: Scalars['Boolean'];
  idDocumentType: Scalars['Boolean'];
  incomeOfficial: Scalars['Boolean'];
  lastName: Scalars['Boolean'];
  lastNameSecondary: Scalars['Boolean'];
  leadPreferredLoanTypes: Scalars['Boolean'];
  loanAmount: Scalars['Boolean'];
  loanPurpose: Scalars['Boolean'];
  loanPurposeType: Scalars['Boolean'];
  loanTermInDays: Scalars['Boolean'];
  loanTermInMonths: Scalars['Boolean'];
  loanUrgency: Scalars['Boolean'];
  locationOfBirth: Scalars['Boolean'];
  middleName: Scalars['Boolean'];
  monthlyIncome: Scalars['Boolean'];
  personalId: Scalars['Boolean'];
};

export type LoanInformationInput = {
  activityId: Scalars['String'];
  dueDate?: InputMaybe<Scalars['String']>;
  interestRate?: InputMaybe<InterestRateInput>;
  loanAmount?: InputMaybe<Scalars['Long']>;
  loanRepaymentAmount?: InputMaybe<Scalars['Long']>;
};

export enum LoanPurpose {
  BusinessExpenses = 'BUSINESS_EXPENSES',
  CreditCard = 'CREDIT_CARD',
  EducationExpenses = 'EDUCATION_EXPENSES',
  ElectronicsPurchase = 'ELECTRONICS_PURCHASE',
  EmergencyOrUnexpectedExpenses = 'EMERGENCY_OR_UNEXPECTED_EXPENSES',
  EstablishingCreditHistory = 'ESTABLISHING_CREDIT_HISTORY',
  GroceriesAndDailyExpenses = 'GROCERIES_AND_DAILY_EXPENSES',
  HomeMaintenanceAndImprovement = 'HOME_MAINTENANCE_AND_IMPROVEMENT',
  LoanPaymentOrConsolidation = 'LOAN_PAYMENT_OR_CONSOLIDATION',
  MajorPurchase = 'MAJOR_PURCHASE',
  MedicalExpenses = 'MEDICAL_EXPENSES',
  Other = 'OTHER',
  PaymentForServices = 'PAYMENT_FOR_SERVICES',
  RelocationExpenses = 'RELOCATION_EXPENSES',
  SpecialEvent = 'SPECIAL_EVENT',
  Travel = 'TRAVEL',
  VehiclePurchase = 'VEHICLE_PURCHASE',
  VehicleRepairOrPurchase = 'VEHICLE_REPAIR_OR_PURCHASE'
}

export enum LoanType {
  BusinessLoan = 'BUSINESS_LOAN',
  CarLoan = 'CAR_LOAN',
  CreditCard = 'CREDIT_CARD',
  DebtConsolidationLoan = 'DEBT_CONSOLIDATION_LOAN',
  HomeEquityLoan = 'HOME_EQUITY_LOAN',
  Mortgage = 'MORTGAGE',
  PersonalLoan = 'PERSONAL_LOAN',
  QuickLoan = 'QUICK_LOAN',
  RefinanceLoan = 'REFINANCE_LOAN',
  StudentLoan = 'STUDENT_LOAN'
}

export enum LoanUrgency {
  NonUrgent = 'NON_URGENT',
  Within_1Months = 'WITHIN_1_MONTHS',
  Within_1Week = 'WITHIN_1_WEEK',
  Within_3Months = 'WITHIN_3_MONTHS',
  Within_24Hours = 'WITHIN_24_HOURS'
}

export type LoginInput = {
  email?: InputMaybe<Scalars['String']>;
  leadId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export type LongRangeInput = {
  from?: InputMaybe<Scalars['Long']>;
  to?: InputMaybe<Scalars['Long']>;
};

export enum LotteryPrize {
  Airpods = 'AIRPODS',
  NoPrize = 'NO_PRIZE',
  Points_1 = 'POINTS_1',
  Points_10 = 'POINTS_10',
  Points_20 = 'POINTS_20',
  Points_50 = 'POINTS_50',
  Points_100 = 'POINTS_100',
  Points_200 = 'POINTS_200'
}

export type LotteryResult = {
  __typename?: 'LotteryResult';
  accepted: Scalars['Boolean'];
  entryId?: Maybe<Scalars['String']>;
  prize?: Maybe<LotteryPrize>;
  prizePool?: Maybe<Array<LotteryPrize>>;
};

export type Loyalty = {
  __typename?: 'Loyalty';
  points: Scalars['Int'];
};

export type MarketingApiOffer = {
  __typename?: 'MarketingApiOffer';
  partnerId: Scalars['String'];
  score?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type MarketingConsent = {
  communicationAllowed: Scalars['Boolean'];
};

export type MarketingDataInput = {
  advertisingId?: InputMaybe<Scalars['String']>;
  appsflyerId?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<Scalars['String']>;
  facebookBrowserId?: InputMaybe<Scalars['String']>;
  facebookClickId?: InputMaybe<Scalars['String']>;
  googleAnalyticsId?: InputMaybe<Scalars['String']>;
  googleClickId?: InputMaybe<Scalars['String']>;
  pinterestClickId?: InputMaybe<Scalars['String']>;
  publisherClickId?: InputMaybe<Scalars['String']>;
  publisherName?: InputMaybe<Scalars['String']>;
  queryParams?: InputMaybe<Array<InputMaybe<QueryParamInput>>>;
  referrer?: InputMaybe<Scalars['String']>;
  snowplowSessionId?: InputMaybe<Scalars['String']>;
  tikTokClickId?: InputMaybe<Scalars['String']>;
  twitterClickId?: InputMaybe<Scalars['String']>;
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmContent?: InputMaybe<Scalars['String']>;
  utmId?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
};

export enum MobileAppMode {
  Loose = 'LOOSE',
  Strict = 'STRICT'
}

export type MobileIdentifierInput = {
  googlePlayInstant?: InputMaybe<Scalars['String']>;
  identifier: Scalars['String'];
  installBeginTimestampSeconds?: InputMaybe<Scalars['String']>;
  installBeginTimestampServerSeconds?: InputMaybe<Scalars['String']>;
  installReferrer?: InputMaybe<Scalars['String']>;
  installVersion?: InputMaybe<Scalars['String']>;
  leadId?: InputMaybe<Scalars['String']>;
  leadIntentId?: InputMaybe<Scalars['String']>;
  platform: Scalars['String'];
  referrerClickTimestampSeconds?: InputMaybe<Scalars['String']>;
  referrerClickTimestampServerSeconds?: InputMaybe<Scalars['String']>;
};

export type MotorbikeInsuranceCertificateInput = {
  address: Scalars['String'];
  fullName: Scalars['String'];
};

export type MotorbikeInsuranceDetailsInput = {
  certificateInput?: InputMaybe<MotorbikeInsuranceCertificateInput>;
  chassisNumber?: InputMaybe<Scalars['String']>;
  engineNumber?: InputMaybe<Scalars['String']>;
  hasLicensePlate?: InputMaybe<BooleanFieldInput>;
  licensePlateNumber?: InputMaybe<Scalars['String']>;
  motorbikeType?: InputMaybe<MotorbikeType>;
  riderAccidentInsurance?: InputMaybe<MotorbikeRiderAccidentInsuranceInput>;
};

export type MotorbikeRiderAccidentInsuranceInput = {
  payoutAmount: Scalars['Int'];
};

export enum MotorbikeType {
  Electric = 'ELECTRIC',
  LowPower = 'LOW_POWER',
  MediumPower = 'MEDIUM_POWER',
  ThreeWheeler = 'THREE_WHEELER'
}

export type Mutation = {
  __typename?: 'Mutation';
  addApplicationLoanRepaymentDate: Scalars['Boolean'];
  addContactDetails: LeadUpdateStatus;
  addDevice: Scalars['Boolean'];
  addLeadIntentEmail?: Maybe<LeadIntentStatus>;
  addOfferRatingFeedback: MyApplicationFeedback;
  addPartnerRegistrationConsents: Lead;
  authenticateAccountBySocialLogin?: Maybe<Account>;
  authenticateZaloUserAndCreateIfNotExists?: Maybe<Account>;
  claimLoyaltyPoints: ClaimLoyaltyPointsResponse;
  claimPrize: Scalars['Boolean'];
  confirmAnonEmailWithCode?: Maybe<Scalars['String']>;
  confirmEmail?: Maybe<Scalars['String']>;
  confirmEmailWithCode: Scalars['Boolean'];
  confirmFinbroOtp: FinbroConversionResponse;
  confirmInsurancePolicy: InsuranceInteraction;
  confirmLeadEmailWithLongLivedToken: Scalars['Boolean'];
  confirmPhoneNumber: Scalars['Boolean'];
  /** Nullable because error might be thrown */
  createAccount?: Maybe<Account>;
  createInsuranceInteraction: InsuranceInteraction;
  createLeadFromSocialLogin?: Maybe<Lead>;
  /** Nullable because error might be thrown */
  createLeadIntent?: Maybe<LeadIntentStatus>;
  initPayment?: Maybe<InitPaymentResponse>;
  initiateInsurancePayment: InsuranceInteraction;
  initiateZaloLogin: Scalars['String'];
  leaveFeedback: Scalars['Boolean'];
  loginAccount?: Maybe<Account>;
  newLeadForPartnerOffer: LeadForPartnerOfferStatus;
  optInMarketingCommunication: Scalars['Boolean'];
  prepareOfferListing: OfferListing;
  registerLeadInLottery: LotteryResult;
  resetPassword?: Maybe<Lead>;
  resetPasswordByEmailConfirmationCode?: Maybe<Lead>;
  sendAnonEmailConfirmationCode: Scalars['Boolean'];
  sendEmailConfirmationCode: Scalars['Boolean'];
  sendEmailConfirmationEmail: Scalars['Boolean'];
  sendFinbroOtp: FinbroOtpResponse;
  sendPasswordResetEmail: Scalars['Boolean'];
  sendPhoneConfirmationCode: Scalars['Boolean'];
  sendS2sRequest: LeadS2sResponse;
  sendZaloPhoneConfirmationCode: Scalars['Boolean'];
  storeInsuranceInteraction: InsuranceInteraction;
  storeLoanInformation: Scalars['Boolean'];
  storeMobileIdentifier: Scalars['Boolean'];
  storeRandomData: Scalars['Boolean'];
  triggerMarketingEvent: Scalars['Boolean'];
  updateLead?: Maybe<Lead>;
  updateLoanApplication: LoanApplicationStatus;
  updateOfferRatingFeedback: MyApplicationFeedback;
  validateZaloSSOToken?: Maybe<ZaloValidationResponse>;
};


export type MutationAddApplicationLoanRepaymentDateArgs = {
  leadId: Scalars['String'];
  req: AddMyApplicationLoanRepaymentDate;
};


export type MutationAddContactDetailsArgs = {
  leadId: Scalars['String'];
  request: UpdateLeadRequest;
};


export type MutationAddDeviceArgs = {
  input: AddDeviceInput;
};


export type MutationAddLeadIntentEmailArgs = {
  email: Scalars['String'];
  intentId: Scalars['String'];
};


export type MutationAddOfferRatingFeedbackArgs = {
  leadId: Scalars['String'];
  req: AddMyApplicationFeedbackRequest;
};


export type MutationAddPartnerRegistrationConsentsArgs = {
  input: AddPartnerRegistrationConsentsInput;
  leadId: Scalars['String'];
};


export type MutationAuthenticateAccountBySocialLoginArgs = {
  input: SingleSignOnInput;
};


export type MutationAuthenticateZaloUserAndCreateIfNotExistsArgs = {
  input: ZaloSignOnInput;
};


export type MutationClaimLoyaltyPointsArgs = {
  code: Scalars['String'];
  leadId: Scalars['String'];
};


export type MutationClaimPrizeArgs = {
  entryId: Scalars['String'];
};


export type MutationConfirmAnonEmailWithCodeArgs = {
  code: Scalars['String'];
  email: Scalars['String'];
};


export type MutationConfirmEmailArgs = {
  token: Scalars['String'];
};


export type MutationConfirmEmailWithCodeArgs = {
  code: Scalars['String'];
  leadId: Scalars['String'];
};


export type MutationConfirmFinbroOtpArgs = {
  leadId: Scalars['String'];
  offerListingId?: InputMaybe<Scalars['Int']>;
  otpCode: Scalars['String'];
};


export type MutationConfirmInsurancePolicyArgs = {
  input: ConfirmInsurancePolicyInput;
};


export type MutationConfirmLeadEmailWithLongLivedTokenArgs = {
  leadId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationConfirmPhoneNumberArgs = {
  code: Scalars['String'];
  leadId: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateInsuranceInteractionArgs = {
  input: CreateInsuranceInteractionInput;
};


export type MutationCreateLeadFromSocialLoginArgs = {
  input: SingleSignOnInput;
};


export type MutationCreateLeadIntentArgs = {
  input: CreateLeadIntentInput;
};


export type MutationInitPaymentArgs = {
  leadId: Scalars['String'];
};


export type MutationInitiateInsurancePaymentArgs = {
  input: InitiateInsurancePaymentInput;
};


export type MutationInitiateZaloLoginArgs = {
  appendToState?: InputMaybe<Scalars['String']>;
};


export type MutationLeaveFeedbackArgs = {
  input: LeaveFeedbackInput;
  leadId: Scalars['String'];
};


export type MutationLoginAccountArgs = {
  input: LoginInput;
};


export type MutationNewLeadForPartnerOfferArgs = {
  offerId: Scalars['String'];
};


export type MutationOptInMarketingCommunicationArgs = {
  leadId: Scalars['String'];
};


export type MutationPrepareOfferListingArgs = {
  additionalFeatureInput?: InputMaybe<AdditionalFeatureInput>;
  disableScoring?: InputMaybe<Scalars['Boolean']>;
  leadId: Scalars['String'];
  marketing?: InputMaybe<MarketingDataInput>;
  offerListingMode?: InputMaybe<OfferListingMode>;
  offerListingType?: InputMaybe<OfferListingType>;
  productInput?: InputMaybe<SelectedProductInput>;
  requestId?: InputMaybe<Scalars['String']>;
};


export type MutationRegisterLeadInLotteryArgs = {
  leadId: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResetPasswordByEmailConfirmationCodeArgs = {
  code: Scalars['String'];
  leadId: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationSendAnonEmailConfirmationCodeArgs = {
  email: Scalars['String'];
};


export type MutationSendEmailConfirmationCodeArgs = {
  leadId: Scalars['String'];
};


export type MutationSendEmailConfirmationEmailArgs = {
  leadId: Scalars['String'];
};


export type MutationSendFinbroOtpArgs = {
  leadId: Scalars['String'];
  offerListingId?: InputMaybe<Scalars['Int']>;
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
};


export type MutationSendPhoneConfirmationCodeArgs = {
  leadId: Scalars['String'];
  phoneNumber?: InputMaybe<PhoneNumberInput>;
};


export type MutationSendS2sRequestArgs = {
  leadId: Scalars['String'];
  offerListingId?: InputMaybe<Scalars['Int']>;
  partnerId: Scalars['String'];
  s2sApplicationInput?: InputMaybe<S2sApplicationInput>;
};


export type MutationSendZaloPhoneConfirmationCodeArgs = {
  leadId: Scalars['String'];
  phoneNumber?: InputMaybe<PhoneNumberInput>;
};


export type MutationStoreInsuranceInteractionArgs = {
  input: StoreInsuranceInteractionInput;
};


export type MutationStoreLoanInformationArgs = {
  input: LoanInformationInput;
  leadId: Scalars['String'];
};


export type MutationStoreMobileIdentifierArgs = {
  input: MobileIdentifierInput;
};


export type MutationStoreRandomDataArgs = {
  key: Scalars['String'];
  leadId: Scalars['String'];
  value: Scalars['String'];
};


export type MutationTriggerMarketingEventArgs = {
  eventKey: Scalars['String'];
  leadId: Scalars['String'];
};


export type MutationUpdateLeadArgs = {
  leadId: Scalars['String'];
  request: UpdateLeadRequest;
};


export type MutationUpdateLoanApplicationArgs = {
  input: LoanApplicationInput;
  leadId: Scalars['String'];
};


export type MutationUpdateOfferRatingFeedbackArgs = {
  leadId: Scalars['String'];
  req: UpdateMyApplicationFeedbackInput;
};


export type MutationValidateZaloSsoTokenArgs = {
  input: SingleSignOnInput;
};

export type MyActivityOfferPartnerInfo = {
  __typename?: 'MyActivityOfferPartnerInfo';
  avgResponseTime: Scalars['Float'];
  displayName: Scalars['String'];
  imagePath?: Maybe<Scalars['String']>;
  offerId: Scalars['String'];
  partnerId: Scalars['String'];
};

export type MyApplicationFeedback = {
  __typename?: 'MyApplicationFeedback';
  activityOfferId: Scalars['String'];
  activityStatus?: Maybe<FeedbackActivityStatus>;
  additionalComments?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalReviewTags?: Maybe<Array<Scalars['String']>>;
  applicationProcess?: Maybe<FeedbackRating>;
  comment?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  easeOfReceivingLoan?: Maybe<FeedbackRating>;
  id: Scalars['ID'];
  loanDueDate?: Maybe<Scalars['String']>;
  loanIssued?: Maybe<Scalars['Boolean']>;
  partner: MyActivityOfferPartnerInfo;
  personalExperience?: Maybe<Scalars['String']>;
  rating?: Maybe<FeedbackRating>;
  recommendLender?: Maybe<Scalars['Boolean']>;
  reviewTags?: Maybe<Array<Scalars['String']>>;
  setPaymentReminder?: Maybe<Scalars['Boolean']>;
  timeToLoan?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
};

export type NearPrimeApplicationRequest = {
  amount: Scalars['Int'];
  termMonths?: InputMaybe<Scalars['Int']>;
};

export type NearPrimeOfferDetails = {
  __typename?: 'NearPrimeOfferDetails';
  amount: LoanAmount;
  discriminator: Scalars['String'];
  displayName: Scalars['String'];
  images: Array<Image>;
  interestRate: InterestRate;
  requirements: Array<Requirement>;
  ribbons: Array<Ribbon>;
  timeToDecision: TimeToDecision;
  timeToMoney: TimeToMoney;
  usps: Array<OfferUsp>;
};

export type Offer = {
  __typename?: 'Offer';
  averageRating?: Maybe<Scalars['Float']>;
  company: Company;
  details?: Maybe<OfferDetails>;
  integrationType: IntegrationType;
  /** @deprecated loyalty program is disabled */
  loyalty?: Maybe<OfferLoyaltyPoints>;
  partnerId: Scalars['String'];
  productType: ProductType;
  rankByAvgRating?: Maybe<Scalars['Int']>;
  ratingCount?: Maybe<Scalars['Long']>;
  score?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type OfferDetails = CreditCardOfferDetails | NearPrimeOfferDetails | SubPrimeOfferDetails;

export type OfferListing = {
  __typename?: 'OfferListing';
  algorithm?: Maybe<Scalars['String']>;
  allOffers: Array<Offer>;
  id: Scalars['Int'];
  /**
   * https://jeff-app.atlassian.net/browse/PROD-3757 remove from web
   * @deprecated Use allOffers
   */
  marketingApiOffers?: Maybe<Array<Maybe<MarketingApiOffer>>>;
  ready: Scalars['Boolean'];
  /**
   * https://jeff-app.atlassian.net/browse/PROD-3757 remove from web
   * @deprecated Use allOffers
   */
  redirectOffers?: Maybe<Array<Maybe<RedirectOffer>>>;
  /**
   * https://jeff-app.atlassian.net/browse/PROD-3757 remove from web
   * @deprecated Use allOffers
   */
  s2sOffers?: Maybe<Array<Maybe<S2sOffer>>>;
};

export enum OfferListingMode {
  AutoRedirect = 'AUTO_REDIRECT',
  Standard = 'STANDARD'
}

export enum OfferListingType {
  All = 'ALL',
  MarketingApiIncluded = 'MARKETING_API_INCLUDED',
  Mixed = 'MIXED',
  NearPrime = 'NEAR_PRIME',
  Unified = 'UNIFIED'
}

export type OfferLoyaltyPoints = {
  __typename?: 'OfferLoyaltyPoints';
  successfulTransaction?: Maybe<Scalars['Int']>;
};

export type OfferUsp = {
  __typename?: 'OfferUSP';
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PageRequest = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};

export type Param = {
  key: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type PartnerConsentStep = {
  __typename?: 'PartnerConsentStep';
  companies: Array<Scalars['String']>;
  show: Scalars['Boolean'];
};

export type PartnerOffer = {
  __typename?: 'PartnerOffer';
  algorithm?: Maybe<Scalars['String']>;
  offerListingId?: Maybe<Scalars['Long']>;
  partner: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type PartnerOfferRedirectInput = {
  allowRepeatedRedirect?: InputMaybe<Scalars['Boolean']>;
  ignorePolicies?: InputMaybe<Scalars['Boolean']>;
  leadId: Scalars['String'];
  partner: Scalars['String'];
};

export type PendingOffer = {
  __typename?: 'PendingOffer';
  createdAt: Scalars['String'];
  details?: Maybe<OfferDetails>;
  offerId: Scalars['String'];
};

export type PhoneNumberInput = {
  number: Scalars['String'];
  prefix: Scalars['String'];
};

export enum PolicyStatus {
  Confirmed = 'CONFIRMED',
  Created = 'CREATED',
  Draft = 'DRAFT',
  Issued = 'ISSUED',
  Paid = 'PAID',
  PaymentFailure = 'PAYMENT_FAILURE'
}

export type ProductPriceQuote = {
  __typename?: 'ProductPriceQuote';
  price: Scalars['Float'];
  productId: Scalars['String'];
};

export type ProductSuggestion = {
  __typename?: 'ProductSuggestion';
  suggestedProduct: ProductType;
};

export enum ProductType {
  CreditCard = 'CREDIT_CARD',
  /** @deprecated use lineOfBusiness together with SelectedProductInput */
  EWallet = 'E_WALLET',
  /** @deprecated use lineOfBusiness together with SelectedProductInput */
  Insurance = 'INSURANCE',
  MandatoryCarInsurance = 'MANDATORY_CAR_INSURANCE',
  MotorbikeInsurance = 'MOTORBIKE_INSURANCE',
  NearPrime = 'NEAR_PRIME',
  Securities = 'SECURITIES',
  SubPrime = 'SUB_PRIME',
  TravelInsurance = 'TRAVEL_INSURANCE'
}

export type PublisherCustomParam = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type QualityScore = {
  __typename?: 'QualityScore';
  score: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  fetchAnonymousOffers: OfferListing;
  fetchInsuranceInteraction: InsuranceInteraction;
  fetchInsuranceOffers: InsuranceOfferPollingResult;
  fetchInsurancePolicyStatus: InsurancePolicyStatusPollingResult;
  fetchLatestApprovedApiResponses: Array<Maybe<Scalars['String']>>;
  fetchLatestInsuranceInteractionWithPaymentNotCompleted?: Maybe<InsuranceInteraction>;
  fetchLead?: Maybe<Lead>;
  fetchLeadAccountStatus: LeadAccountStatus;
  fetchLeadApplicationStatus?: Maybe<LoanApplicationStatus>;
  fetchLeadIntentStatus?: Maybe<LeadIntentStatus>;
  fetchMobileAppMode: MobileAppMode;
  fetchMyActivityFeedback?: Maybe<MyApplicationFeedback>;
  fetchOfferFeedbackSummaries: Array<FeedbackAggregatedSummary>;
  fetchOfferListing: OfferListing;
  fetchOfferUserFeedback: UserFeedbackSearchResultPage;
  fetchOfferUserFeedbackTop: QueryTopUserFeedbackResponse;
  fetchOffersAnonymously: TypedPartnerOffers;
  fetchPartnerOfferRedirectUrl: Scalars['String'];
  fetchPartnerOfferRedirectUrlIfRedirectPossible?: Maybe<Scalars['String']>;
  fetchPendingApiOffers: Array<PendingOffer>;
  fetchQualityScore?: Maybe<QualityScore>;
  fetchSortedOffers: Array<Maybe<Scalars['String']>>;
  fetchSuggestedProductType: ProductSuggestion;
  getEmailValidationData: EmailValidationData;
  getProductPriceQuote: ProductPriceQuote;
  isEmailConfirmationTokenValid: Scalars['Boolean'];
  isLatestApiResponseApproved: Scalars['Boolean'];
  validateEmail: EmailValidationResponse;
};


export type QueryFetchAnonymousOffersArgs = {
  marketing?: InputMaybe<MarketingDataInput>;
  productInput?: InputMaybe<SelectedProductInput>;
  productType?: InputMaybe<ProductType>;
};


export type QueryFetchInsuranceInteractionArgs = {
  interactionId: Scalars['String'];
};


export type QueryFetchInsuranceOffersArgs = {
  interactionId: Scalars['String'];
};


export type QueryFetchInsurancePolicyStatusArgs = {
  interactionId: Scalars['String'];
};


export type QueryFetchLatestApprovedApiResponsesArgs = {
  leadId: Scalars['String'];
  offerIds: Array<Scalars['String']>;
};


export type QueryFetchLatestInsuranceInteractionWithPaymentNotCompletedArgs = {
  leadId: Scalars['String'];
};


export type QueryFetchLeadArgs = {
  leadId: Scalars['String'];
};


export type QueryFetchLeadAccountStatusArgs = {
  leadId: Scalars['String'];
};


export type QueryFetchLeadApplicationStatusArgs = {
  leadId: Scalars['String'];
};


export type QueryFetchLeadIntentStatusArgs = {
  intentId: Scalars['String'];
};


export type QueryFetchMobileAppModeArgs = {
  deviceDetails: Scalars['String'];
  leadId: Scalars['String'];
};


export type QueryFetchMyActivityFeedbackArgs = {
  activityId: Scalars['String'];
  leadId: Scalars['String'];
};


export type QueryFetchOfferFeedbackSummariesArgs = {
  offerIds: Array<Scalars['String']>;
};


export type QueryFetchOfferListingArgs = {
  leadId: Scalars['String'];
  offerListingId: Scalars['Int'];
  productInput?: InputMaybe<SelectedProductInput>;
  secondsSincePrepare?: InputMaybe<Scalars['Int']>;
};


export type QueryFetchOfferUserFeedbackArgs = {
  feedbackRequest: QueryUserFeedbackRequest;
  pageRequest: PageRequest;
};


export type QueryFetchOfferUserFeedbackTopArgs = {
  queryRequest: QueryTopUserFeedbackRequest;
};


export type QueryFetchOffersAnonymouslyArgs = {
  countryCode: Scalars['String'];
  partnerType?: InputMaybe<Scalars['String']>;
  productInput?: InputMaybe<SelectedProductInput>;
};


export type QueryFetchPartnerOfferRedirectUrlArgs = {
  input: PartnerOfferRedirectInput;
};


export type QueryFetchPartnerOfferRedirectUrlIfRedirectPossibleArgs = {
  leadId: Scalars['String'];
  marketingData: MarketingDataInput;
  partnerOfferId: Scalars['String'];
};


export type QueryFetchPendingApiOffersArgs = {
  leadId: Scalars['String'];
};


export type QueryFetchQualityScoreArgs = {
  leadId: Scalars['String'];
};


export type QueryFetchSortedOffersArgs = {
  offerIds: Array<Scalars['String']>;
};


export type QueryFetchSuggestedProductTypeArgs = {
  leadId: Scalars['String'];
};


export type QueryGetEmailValidationDataArgs = {
  email: Scalars['String'];
};


export type QueryGetProductPriceQuoteArgs = {
  leadId: Scalars['String'];
  productId: Scalars['String'];
};


export type QueryIsEmailConfirmationTokenValidArgs = {
  leadId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryIsLatestApiResponseApprovedArgs = {
  leadId?: InputMaybe<Scalars['String']>;
  offerId?: InputMaybe<Scalars['String']>;
};


export type QueryValidateEmailArgs = {
  email: Scalars['String'];
};

export type QueryParamInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type QueryTopUserFeedbackRequest = {
  limit: Scalars['Int'];
  offerIds: Array<Scalars['String']>;
  topBy: TopBy;
};

export type QueryTopUserFeedbackResponse = {
  __typename?: 'QueryTopUserFeedbackResponse';
  feedbacks: Array<UserFeedbackSearchEntry>;
};

export type QueryUserFeedbackRequest = {
  filters?: InputMaybe<FeedbackFilters>;
  offerIds: Array<Scalars['String']>;
  sort?: InputMaybe<Array<SortBy>>;
};

export type RedirectOffer = {
  __typename?: 'RedirectOffer';
  partnerId: Scalars['String'];
  score?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type Requirement = {
  __typename?: 'Requirement';
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Ribbon = {
  __typename?: 'Ribbon';
  content: Scalars['String'];
};

export type S2sApplicationInput = {
  loanAmount?: InputMaybe<Scalars['Long']>;
};

export type S2sOffer = {
  __typename?: 'S2sOffer';
  partnerId: Scalars['String'];
  score?: Maybe<Scalars['Float']>;
};

export type S2sPartnerOffer = {
  __typename?: 'S2sPartnerOffer';
  partner: Scalars['String'];
  resultType: Scalars['String'];
};

export enum SalaryCycle {
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  SemiMonthly = 'SEMI_MONTHLY',
  Weekly = 'WEEKLY'
}

export type SelectedProductInput = {
  lineOfBusiness: LineOfBusiness;
  preferredProduct?: InputMaybe<ProductType>;
  productType?: InputMaybe<ProductType>;
};

export type ShortTermLoanApplicationRequest = {
  amount: LongRangeInput;
  term: TermRangeInput;
};

export type SingleSignOnInput = {
  account?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  leadPageId?: InputMaybe<Scalars['String']>;
  marketing?: InputMaybe<MarketingDataInput>;
  provider: Scalars['String'];
  publisherData?: InputMaybe<LeadPublisherData>;
  selectedProduct?: InputMaybe<SelectedProductInput>;
  stateCode?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type SortBy = {
  by: SortField;
  direction: SortDirection;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortField {
  Rating = 'RATING',
  SubmittedDate = 'SUBMITTED_DATE'
}

export type StoreInsuranceInteractionInput = {
  interactionId: Scalars['String'];
  motorbike?: InputMaybe<MotorbikeInsuranceDetailsInput>;
  startDate?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<TermInput>;
};

export type SubPrimeOfferDetails = {
  __typename?: 'SubPrimeOfferDetails';
  amount: LoanAmount;
  discriminator: Scalars['String'];
  displayName: Scalars['String'];
  images: Array<Image>;
  interestRate: InterestRate;
  requirements: Array<Requirement>;
  ribbons: Array<Ribbon>;
  timeToDecision: TimeToDecision;
  timeToMoney: TimeToMoney;
  usps: Array<OfferUsp>;
};

export type TermInput = {
  count: Scalars['Int'];
  unit: TermUnit;
};

export type TermRangeInput = {
  from?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['Int']>;
  unit: Scalars['String'];
};

export enum TermUnit {
  Year = 'YEAR'
}

export type TimeToDecision = {
  __typename?: 'TimeToDecision';
  avgDays?: Maybe<Scalars['Int']>;
  avgMinutes: Scalars['Int'];
};

export type TimeToMoney = {
  __typename?: 'TimeToMoney';
  avgDays?: Maybe<Scalars['Int']>;
  avgMinutes: Scalars['Int'];
};

export enum TopBy {
  Rating = 'RATING',
  SubmittedDate = 'SUBMITTED_DATE'
}

export type TypedPartnerOffers = {
  __typename?: 'TypedPartnerOffers';
  offerListingId?: Maybe<Scalars['Int']>;
  redirectPartnerOffers: Array<PartnerOffer>;
  s2sPartnerOffers: Array<S2sPartnerOffer>;
};

export type UpdateLeadRequest = {
  email?: InputMaybe<Scalars['String']>;
  marketingConsent?: InputMaybe<MarketingConsent>;
  marketingData?: InputMaybe<MarketingDataInput>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
};

export type UpdateMyApplicationFeedbackInput = {
  activityOfferId: Scalars['String'];
  additionalComments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  additionalReviewTags?: InputMaybe<Array<Scalars['String']>>;
  applicationProcess?: InputMaybe<FeedbackRating>;
  comment?: InputMaybe<Scalars['String']>;
  easeOfReceivingLoan?: InputMaybe<FeedbackRating>;
  loanDueDate?: InputMaybe<Scalars['String']>;
  loanIssued?: InputMaybe<Scalars['Boolean']>;
  personalExperience?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<FeedbackRating>;
  recommendLender?: InputMaybe<Scalars['Boolean']>;
  reviewTags?: InputMaybe<Array<Scalars['String']>>;
  setPaymentReminder?: InputMaybe<Scalars['Boolean']>;
  timeToLoan?: InputMaybe<Scalars['String']>;
};

export type UserFeedbackSearchEntry = {
  __typename?: 'UserFeedbackSearchEntry';
  content: Scalars['String'];
  date: Scalars['String'];
  id: Scalars['String'];
  isVerified: Scalars['Boolean'];
  location: Scalars['String'];
  offerId: Scalars['String'];
  rating?: Maybe<FeedbackRating>;
  userName: Scalars['String'];
};

export type UserFeedbackSearchResultPage = {
  __typename?: 'UserFeedbackSearchResultPage';
  items: Array<UserFeedbackSearchEntry>;
  page: Scalars['Int'];
  totalItems: Scalars['Int'];
};

export type ZaloSignOnInput = {
  email?: InputMaybe<Scalars['String']>;
  marketingConsent?: InputMaybe<MarketingConsent>;
  marketingData?: InputMaybe<MarketingDataInput>;
  ssoEntryId?: InputMaybe<Scalars['Long']>;
};

export type ZaloValidationResponse = {
  __typename?: 'ZaloValidationResponse';
  newUser: Scalars['Boolean'];
  ssoEntryId?: Maybe<Scalars['Long']>;
};
