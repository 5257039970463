import {
  createContext,
  useCallback,
  useContext,
  useState,
  type ReactNode,
} from "react";
import { trackExperimentEngagement } from "@jeff/web-analytics/src/snowplow/events";
import { type ProductConfig } from "@domain/product-config/types";
import { type ExperimentProps } from "@integration/experiments/types";
import { appState } from "@domain/app-state/csr";
import { REGISTRATION_TIMER_EXP_ID } from "@integration/experiments/constants";
import { getApplicationFlowTimerExpEnabled } from "./helpers/getApplicationFlowTimerExpEnabled";

const ApplicationFlowTimerContext = createContext<{
  initApplicationFlowTimer: (leadId: string) => Promise<void> | undefined;
  showTimerModal: boolean;
  handleConfirm: () => void;
  isEnabled: boolean;
  registrationTime: Date | null;
}>({
  initApplicationFlowTimer: () => undefined,
  showTimerModal: false,
  handleConfirm: () => null,
  isEnabled: false,
  registrationTime: null,
});

interface Props {
  children: ReactNode;
  productConfig: ProductConfig;
  experiments: ExperimentProps | undefined;
  brandId: string;
}

export const ApplicationFlowTimerProvider = ({
  children,
  productConfig,
  experiments,
  brandId,
}: Props) => {
  const [showTimerModal, setShowTimerModal] = useState(false);
  const [resolveModal, setResolveModal] = useState<(() => void) | null>(null);

  const isEnabled = getApplicationFlowTimerExpEnabled(
    experiments,
    productConfig,
  );

  const initApplicationFlowTimer = useCallback(
    (leadId: string) => {
      if (productConfig.registrationTimer?.enabled) {
        trackExperimentEngagement({
          experimentId: REGISTRATION_TIMER_EXP_ID,
          experimentVariantId: null,
          isEnabled: !!experiments?.enabled[REGISTRATION_TIMER_EXP_ID],
          leadId,
          brandId,
        });
      }

      if (!isEnabled) {
        appState().setState((prev) => ({
          ...prev,
          registration: { timeLeadCreatedTimestamp: null },
        }));
        return;
      }

      setShowTimerModal(true);
      return new Promise<void>((resolve) => {
        setResolveModal(() => resolve);
      });
    },
    [isEnabled, brandId, experiments, productConfig],
  );

  const handleConfirm = useCallback(() => {
    const time = Date.now();

    appState().setState((prev) => ({
      ...prev,
      registration: { timeLeadCreatedTimestamp: time },
    }));

    setShowTimerModal(false);
    if (resolveModal) {
      resolveModal();
    }
  }, [resolveModal]);

  const getRegistrationTime = () => {
    const timestamp =
      appState().getState().registration?.timeLeadCreatedTimestamp;

    if (isEnabled && timestamp) {
      return new Date(timestamp);
    }

    return null;
  };

  return (
    <ApplicationFlowTimerContext.Provider
      value={{
        initApplicationFlowTimer,
        handleConfirm,
        showTimerModal,
        isEnabled,
        registrationTime: getRegistrationTime(),
      }}
    >
      {children}
    </ApplicationFlowTimerContext.Provider>
  );
};

export const useApplicationFlowTimer = () =>
  useContext(ApplicationFlowTimerContext);
