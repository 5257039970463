import "@integration/csr-polyfill";
import Head from "next/head";
import app from "next/app";
import dynamic from "next/dynamic";
import { NotificationProvider } from "@containers/notifications/NotificationProvider";
import { RouteLoadingContextProvider } from "@providers/RouteLoadingContextProvider/RouteLoadingContextProvider";
import { branding } from "@domain/branding/common/branding";
import { getBrandingFromDomain } from "@domain/branding/common/getBrandingFromDomain";
import { AuthProvider } from "@providers/AuthProvider/AuthProvider";
import { ThemeProvider } from "@providers/ThemeProvider/ThemeProvider";
import { getInitialThemeId } from "@theme/getInitialThemeId";
import { filterQueryParams } from "@integration/query-params/common/filterQueryParams";
import { useConfirmEmailWithLongLivedToken } from "@domain/emails/confirmLeadEmailWithLongLivedToken/useConfirmEmailWithLongLivedToken";
import {
  PARAM_NAME_EMAIL_CONFIRMATION_TOKEN,
  PARAM_NAME_LANGUAGE,
} from "@utils/constants";
import { useReportBrowserData } from "@domain/analytics/browserData/useReportBrowserData";
import { useInitSnowplow } from "@integration/analytics/snowplow/useInitSnowplow";
import { useReportPageView } from "@domain/analytics/useReportPageView";
import { LocalisationProvider } from "@providers/LocalisationProvider/LocalisationProvider";
import { getStoredLang } from "@domain/localisation/common/getStoredLang";
import { useEnabledExperiments } from "@integration/experiments/csr/useGetEnabledExperiments";
import { getFaviconFromDomain } from "@domain/branding/common/getFaviconFromDomain";
import { ExperimentProvider } from "@providers/ExperimentsProvider/ExperimentProvider";
import { useInitPushengage } from "@integration/pushengage/useInitPushengage";
import { useShouldStartLoadingGlobalScripts } from "@components/scripts/useShouldStartLoadingGlobalScripts";
import { useLeadIdNext } from "@domain/leadId/csr/useLeadIdNext";
import { ErrorBoundary, onError } from "@integration/bugsnag/csr";
import { AdsGlobalProvider } from "@providers/Ads/AdsGlobalProvider";
import { TrackBatteryContainer } from "@containers/device-tracking/TrackBatteryContainer";
import { requireHostname } from "@integration/server-utils/server";
import { parseAcceptLanguageHeader } from "@domain/localisation/ssr/parseAcceptLanguageHeader";
import { getAcceptLanguageHeader } from "@integration/server-utils/core";
import { useMeasurePerformance } from "@integration/performance/csr";
import { ApplicationFlowTimerProvider } from "@domain/flows/application-flow-timer/ApplicationFlowTimerContext";
import { fetchProductConfig } from "@domain/product-config/common";
import type {
  FunctionContext,
  NextPageWithLayout,
} from "@integration/next/csr/types";
import type { BrandDomains, EntityId } from "@domain/branding/common";
import type { Language } from "src/common-types";
import type { AppProps, AppContext, AppInitialProps } from "next/app";

const ClarityScript = dynamic(
  () =>
    import("@components/scripts/ClarityScript").then(
      (mod) => mod.ClarityScript,
    ),
  {
    ssr: false,
  },
);
const OneSignalScript = dynamic(
  () =>
    import("@components/scripts/OneSignalScript").then(
      (mod) => mod.OneSignalScript,
    ),
  {
    ssr: false,
  },
);
const CustomerIoScript = dynamic(
  () =>
    import("@components/scripts/CustomerIoScript").then(
      (mod) => mod.CustomerIoScript,
    ),
  {
    ssr: false,
  },
);
const DataLayerScript = dynamic(
  () =>
    import("@components/scripts/DataLayerScript").then(
      (mod) => mod.DataLayerScript,
    ),
  {
    ssr: false,
  },
);
const GoogleTagManagerScript = dynamic(
  () =>
    import("@components/scripts/GoogleTagManagerScript").then(
      (mod) => mod.GoogleTagManagerScript,
    ),
  {
    ssr: false,
  },
);
const PinterestTagScript = dynamic(
  () =>
    import("@components/scripts/PinterestTagScript").then(
      (mod) => mod.PinterestTagScript,
    ),
  {
    ssr: false,
  },
);
const XTagScript = dynamic(
  () => import("@components/scripts/XTagScript").then((mod) => mod.XTagScript),
  {
    ssr: false,
  },
);
const TikTokScript = dynamic(
  () =>
    import("@components/scripts/TikTokTagScript").then(
      (mod) => mod.TikTokTagScript,
    ),
  {
    ssr: false,
  },
);

type InitialProps = {
  themeId?: EntityId;
  hostname?: BrandDomains;
  browserLanguages?: string[];
  preferredLang?: Language;
};

interface AppPropsWithLayout extends AppProps, InitialProps {
  Component: NextPageWithLayout;
}

const InnerApp = ({
  themeId,
  Component,
  pageProps,
  router,
  hostname,
  browserLanguages,
  preferredLang,
}: AppPropsWithLayout) => {
  const { pathname, isReady: isRouterReady, query } = router;
  const leadId = useLeadIdNext();
  const domainName = hostname || branding.brandDomain;

  const emailConfirmationToken =
    filterQueryParams(query)[PARAM_NAME_EMAIL_CONFIRMATION_TOKEN];

  const experiments = useEnabledExperiments({
    leadId,
    brandId: branding.brandId,
  });

  const isSPReady = useInitSnowplow({
    leadId,
    brandId: branding.brandId,
    domainName,
    experiments,
  });

  useReportPageView({
    isRouterReady,
    leadId,
    pathname,
    isSPReady,
    fnContext: (pageProps as { fnContext?: FunctionContext }).fnContext,
  });
  useConfirmEmailWithLongLivedToken({ leadId, emailConfirmationToken });
  useReportBrowserData(isSPReady);
  useInitPushengage({ brandId: branding.brandId });
  useMeasurePerformance();

  const getLayout = Component.getLayout ?? (({ page }) => page);

  const shouldStartLoadingScripts = useShouldStartLoadingGlobalScripts();

  return (
    <>
      {isSPReady && <TrackBatteryContainer />}
      {shouldStartLoadingScripts && (
        <>
          <ClarityScript domainName={domainName} />
          <DataLayerScript />
          <GoogleTagManagerScript gtmId={branding.gtmId} />
          <CustomerIoScript domainName={domainName} onError={onError} />
          <OneSignalScript />
          <PinterestTagScript />
          <XTagScript />
          <TikTokScript />
        </>
      )}
      <AuthProvider>
        <ExperimentProvider leadId={leadId} experimentProps={experiments}>
          <ThemeProvider initialThemeId={themeId}>
            <LocalisationProvider
              domainName={domainName}
              browserLanguages={browserLanguages}
              preferredLang={preferredLang}
            >
              <RouteLoadingContextProvider>
                <AdsGlobalProvider>
                  <ApplicationFlowTimerProvider
                    productConfig={fetchProductConfig(
                      branding.brandId,
                      onError,
                    )}
                    experiments={experiments}
                    brandId={branding.brandId}
                  >
                    <NotificationProvider>
                      {getLayout({
                        page: <Component {...pageProps} />,
                        props: pageProps,
                      })}
                    </NotificationProvider>
                  </ApplicationFlowTimerProvider>
                </AdsGlobalProvider>
              </RouteLoadingContextProvider>
            </LocalisationProvider>
          </ThemeProvider>
        </ExperimentProvider>
      </AuthProvider>
    </>
  );
};

const App = ({
  themeId,
  Component,
  pageProps,
  router,
  hostname,
  browserLanguages,
  preferredLang,
}: AppPropsWithLayout) => {
  const domain = hostname || branding.brandDomain;
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>
          {getBrandingFromDomain(domain, onError).brandName || "Jeff"}
        </title>
        <link
          id="favicon"
          rel="icon"
          href={getFaviconFromDomain({ domain, onError })}
        />
      </Head>
      <ErrorBoundary>
        <InnerApp
          themeId={themeId}
          Component={Component}
          pageProps={pageProps}
          router={router}
          hostname={hostname}
          browserLanguages={browserLanguages}
          preferredLang={preferredLang}
        />
      </ErrorBoundary>
    </>
  );
};

export default App;

App.getInitialProps = async (
  appContext: AppContext,
): Promise<InitialProps & AppInitialProps> => {
  const initialProps = await app.getInitialProps(appContext);
  if (typeof window !== "undefined") {
    return {
      ...initialProps,
    };
  }
  const { ctx } = appContext;
  const hostname = requireHostname({ req: ctx.req }) as BrandDomains;
  const urlParams = filterQueryParams(ctx.query);
  const themeId = getInitialThemeId({ domain: hostname, urlParams });
  const browserLanguages = parseAcceptLanguageHeader(
    getAcceptLanguageHeader({ req: ctx.req }),
  );

  const preferredLang =
    (urlParams?.[PARAM_NAME_LANGUAGE] as Language | undefined) ||
    getStoredLang(ctx);
  return {
    ...initialProps,
    hostname,
    themeId,
    browserLanguages,
    preferredLang,
  };
};
