export const JEFF_VN_HOMEPAGE = "https://www.jeff.vn";
export const DONG247_HOMEPAGE = "https://www.dong247.com";
export const DONG_DEALS_HOMEPAGE = "https://www.dong-deals.com";
export const ASIA_DEALS_HOMEPAGE = "https://www.asia-deals.com";
export const LOCALHOST_HOMEPAGE = "http://localhost:3000";
export const LOANONLINE_HOMEPAGE = "https://www.loanonline.ph";
export const MONEYDONG_HOMEPAGE = "https://www.moneydong.com";
export const PEROLOAN_HOMEPAGE = "https://www.peroloan.ph";
export const PAISA247_IN_HOMEPAGE = "https://www.paisa247.in";
export const FINMART_ID_HOMEPAGE = "https://www.finmart.id";
export const FINMART_VN_HOMEPAGE = "https://www.finmart.vn";
export const FINMERCADO_MX_HOMEPAGE = "https://www.finmercado.mx";
export const FINMERKADO_PH_HOMEPAGE = "https://www.finmerkado.ph";

export const LOCALHOST_DOMAIN = "localhost:3000";
export const NGROK_FUNNEL_DOMAIN = "ngrok-free.app";
export const JEFF_DOMAIN = "app.jeff.vn";
export const JEFF_WEBFLOW_DOMAIN = "www.jeff.vn";
export const JEFF_DEV_DOMAIN = "app-dev.jeff.vn";
export const LOANONLINE_DEV_DOMAIN = "app-dev.loanonline.ph";
export const DONG247_DOMAIN = "app.dong247.com";
export const DONG_DEALS_DOMAIN = "app.dong-deals.com";
export const ASIA_DEALS_DOMAIN = "app.asia-deals.com";
export const LOANONLINE_DOMAIN = "app.loanonline.ph";
export const MONEYDONG_DOMAIN = "app.moneydong.com";
export const PEROLOAN_DOMAIN = "app.peroloan.ph";
export const PAISA247_IN_DOMAIN = "app.paisa247.in";
export const PAISA247_IN_DEV_DOMAIN = "app-dev.paisa247.in";
export const FINMART_ID_DOMAIN = "app.finmart.id";
export const FINMART_ID_DEV_DOMAIN = "app-dev.finmart.id";
export const FINMART_VN_DOMAIN = "app.finmart.vn";
export const FINMART_VN_DEV_DOMAIN = "app-dev.finmart.vn";
export const FINMERCADO_MX_DOMAIN = "app.finmercado.mx";
export const FINMERCADO_MX_DEV_DOMAIN = "app-dev.finmercado.mx";
export const FINMERKADO_PH_DEV_DOMAIN = "app-dev.finmerkado.ph";
export const FINMERKADO_PH_DOMAIN = "app.finmerkado.ph";
export const VERCEL_DOMAIN = "vercel.app";

export const JEFF_VN_COOKIE_DOMAIN = "jeff.vn";
export const DONG247_COOKIE_DOMAIN = "dong247.com";
export const DONG_DEALS_COOKIE_DOMAIN = "dong-deals.com";
export const ASIA_DEALS_COOKIE_DOMAIN = "asia-deals.com";
export const LOCALHOST_COOKIE_DOMAIN = "localhost";
export const LOANONLINE_COOKIE_DOMAIN = "loanonline.ph";
export const MONEYDONG_COOKIE_DOMAIN = "moneydong.com";
export const PEROLOAN_COOKIE_DOMAIN = "peroloan.ph";
export const PAISA_IN_COOKIE_DOMAIN = "paisa247.in";
export const FINMART_ID_COOKIE_DOMAIN = "finmart.id";
export const FINMART_VN_COOKIE_DOMAIN = "finmart.vn";
export const FINMERCADO_MX_COOKIE_DOMAIN = "finmercado.mx";
export const FINMERKADO_PH_COOKIE_DOMAIN = "finmerkado.ph";
export const PAISA247_IN_COOKIE_DOMAIN = "paisa247.in";

export const JEFF_VN_BRAND_ID = "jeff-vn";
export const DONG_DEALS_BRAND_ID = "dong-deals";
export const ASIA_DEALS_BRAND_ID = "asia-deals";
export const LOANONLINE_BRAND_ID = "loanonline-ph";
export const DONG247_BRAND_ID = "dong247";
export const MONEYDONG_BRAND_ID = "moneydong";
export const PEROLOAN_BRAND_ID = "peroloan-ph";
export const PAISA247_IN_BRAND_ID = "paisa247-in";
export const FINMART_ID_BRAND_ID = "finmart-id";
export const FINMART_VN_BRAND_ID = "finmart-vn";
export const FINMERCADO_MX_BRAND_ID = "finmercado-mx";
export const FINMERKADO_PH_BRAND_ID = "finmerkado-ph";
export const JULO_ID_BRAND_ID = "julo-id";
export const TUNAIKU_ID_BRAND_ID = "tunaiku-id";
export const DINEROEXPRES_MX_BRAND_ID = "dineroexpres-mx";
export const KIMBI_MX_BRAND_ID = "kimbi-mx";

export const JEFF_VN_BRAND_NAME = "Jeff";
export const DONG_DEALS_BRAND_NAME = "dong deals";
export const ASIA_DEALS_BRAND_NAME = "asia deals";
export const LOANONLINE_BRAND_NAME = "loanonline";
export const DONG247_BRAND_NAME = "dong247";
export const MONEYDONG_BRAND_NAME = "moneydong";
export const JEFF_LK_BRAND_NAME = "Jeff";
export const PEROLOAN_BRAND_NAME = "peroloan";
export const PAISA247_IN_BRAND_NAME = "paisa247";
export const FINMART_ID_BRAND_NAME = "finmart";
export const FINMART_VN_BRAND_NAME = "finmart";
export const FINMERCARDO_MX_BRAND_NAME = "finmercado";
export const FINMERKARDO_PH_BRAND_NAME = "finmerkado";

//mobile-app-packages
export const MOBILE_APP_PACKAGE_ID_JEFF_VN = "com.jeff.app";
export const MOBILE_APP_PACKAGE_ID_LOANONLINE_PH = "com.jeff.app.ph";
export const MOBILE_APP_PACKAGE_ID_PAISA247_IND = "com.jeff.app.ind";

//mobile app firebase universal link hosts
export const MOBILE_APP_UNIVERSAL_URL_JEFF_VN = "https://jeffapp.page.link";
export const MOBILE_APP_UNIVERSAL_URL_LOANONLINE_PH =
  "https://loanonlineapp.page.link";
export const MOBILE_APP_UNIVERSAL_URL_PAISA247_IND =
  "https://paisa247app.page.link";
